import React from 'react'
import { Grid } from '@material-ui/core'
import {
	Autosuggest,
	CheckboxGroupInput,
	RadioInput,
	TextInput,
	AsyncAutosuggest,
} from 'components'
import { TIMEZONES, USER_ROLES } from 'lib/constants'

const UserForm = ({ user, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={user.name}
				label="Name"
				name="name"
				placeholder="Name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={user.email}
				label="Email"
				name="email"
				placeholder="Email"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Autosuggest
				options={TIMEZONES}
				value={user.time_zone}
				label="Timezone"
				name="time_zone"
				placeholder="Timezone"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Autosuggest
				options={USER_ROLES}
				value={user.role}
				label="Role"
				name="role"
				placeholder="Role"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
)

export default UserForm

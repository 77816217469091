import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { usePlaylists, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Subheader } from 'components'
import PlaylistForm from 'containers/playlists/PlaylistForm'

const PlaylistNew = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const {
		playlistId,
		isLoading,
		isLoaded,
		playlist,
		handleChange,
		createPlaylist,
		findPlaylist,
	} = usePlaylists({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		// if (!playlist.name) {
		// 	showAlertError('No Name was provided')
		// 	return
		// }
		let newPlaylist = await createPlaylist(playlist)
		showAlertSuccess('Playlist has been created')
		navigate(`/playlists/${newPlaylist.id}/edit`)
	}

	useEffect(() => {
		if (id) findPlaylist(id)
	}, [])

	return (
		<>
			<Subheader
				title="Add Playlists"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Box p={4}>
					<PlaylistForm
						playlist={playlist}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save and Continue
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default PlaylistNew

import React from 'react'
import {
	Grid,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@material-ui/core'
import { Autosuggest, TextInput } from 'components'

const TrainerForm = ({
	trainer,
	isEditing,
	handleChange,
}) => {
	const handleSwitch = (ev, value) => {
		let event = { target: { name: 'featured', value } }
		handleChange(event)
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={trainer.name}
					label="Name"
					name="name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					multiline={true}
					rows={3}
					value={trainer.bio}
					label="Bio"
					name="bio"
					placeholder="Bio"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<FormControlLabel
					control={
						<Switch
							checked={trainer.featured ? true : false}
							onChange={handleSwitch}
							name="featured"
							color="primary"
						/>
					}
					label="Featured"
				/>
			</Grid>
		</Grid>
	)
}

export default TrainerForm

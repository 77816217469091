import { colors } from '@material-ui/core'
import { blue, white } from 'theme/colors'

export default {
	primary: {
		contrastText: white,
		dark: '#021CAF',
		main: blue,
		light: blue,
	},
	secondary: {
		contrastText: '#D6D6E7',
		dark: '#23263B',
		main: '#23263B',
		light: '#23263B',
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400],
	},
	text: {
		primary: '#23263B',
		secondary: '#D6D6E7',
		link: '#23263B',
	},
	link: '#23263B',
	icon: '#111',
	background: {
		default: '#F5F5FA',
		paper: white,
	},
	divider: colors.grey[200],
}

import React from 'react'
import {
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'
import { SquareImage } from 'components'

const UserDetails = ({ user }) => (
	<List>
		<ListItem>
			<SquareImage
				src={user.image_url}
				variant="rounded"
				size={240}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Trainer</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user?.trainer?.name
							? user?.trainer?.name
							: '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Name</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user.name ? user.name : '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Email</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user.email ? user.email : '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Timezone</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user?.time_zone ? user?.time_zone : '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Joined</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user?.display_start_date
							? user?.display_start_date
							: '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">
						Joined Time ({user?.time_zone})
					</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user?.start_date_tz
							? user?.start_date_tz
							: '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">
						Current Time ({user?.time_zone})
					</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user?.current_date_tz
							? user?.current_date_tz
							: '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">
						Current day
					</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user.active_day ? user.active_day : '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Role</Typography>
				}
				secondary={
					<Typography variant="body1">
						{user.role ? user.role : '-'}
					</Typography>
				}
			/>
		</ListItem>
	</List>
)

export default UserDetails

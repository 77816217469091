import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Button,
	IconButton,
	ListItem,
	ListItemText,
	ListItemIcon,
	Box,
	ListItemSecondaryAction,
} from '@material-ui/core'
import { SquareImage } from 'components'
import {
	Close,
	ExpandLess,
	ExpandMore,
} from '@material-ui/icons'

const CuratedListPlaylistItem = ({
	playlist,
	handleRemoveClick,
	handleMoveUp,
	handleMoveDown,
	position,
	...props
}) => {
	const navigate = useNavigate()
	const handleClick = () =>
		navigate(`/playlists/${playlist.id}`)

	return (
		<ListItem>
			<ListItemIcon>
				<Box pr={2}>
					<Button onClick={handleClick}>
						<SquareImage
							src={playlist.image_url}
							size={80}
						/>
					</Button>
				</Box>
			</ListItemIcon>
			<ListItemText
				primary={`${position}. ${playlist.name}`}
			/>
			<ListItemSecondaryAction>
				<IconButton
					onClick={() => handleMoveDown(playlist)}
				>
					<ExpandMore />
				</IconButton>
				<IconButton onClick={() => handleMoveUp(playlist)}>
					<ExpandLess />
				</IconButton>
				<IconButton
					onClick={() => handleRemoveClick(playlist)}
				>
					<Close />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default CuratedListPlaylistItem

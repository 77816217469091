import React from 'react'
import AspectRatioImage from './AspectRatioImage'

const DEFAULT_WIDTH = 163
const DEFAULT_HEIGHT = 138

const CarouselImage = AspectRatioImage({
	defaultWidth: DEFAULT_WIDTH,
	defaultHeight: DEFAULT_HEIGHT,
})

export default CarouselImage

import React from 'react'
import { OnlineStatusBadge, UserAvatar } from 'components'

const OnlineStatusAvatar = ({
	user,
	size,
	online = 'true',
}) => (
	<OnlineStatusBadge online={online}>
		<UserAvatar avatar={user.image_url} size={size} />
	</OnlineStatusBadge>
)

export default OnlineStatusAvatar

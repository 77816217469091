import React, { useEffect } from 'react'
import { useAlerts, useNavigation } from 'hooks'
import { useNavigate } from 'react-router-dom'
import {
	SortableTableHeader,
	Placeholder,
	Search,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Box,
	Checkbox,
	Paper,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { sortBy } from 'lodash'
import PlaylistItem from 'containers/playlists/PlaylistItem'

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image url',
		sortable: true,
		hidden: false,
	},
	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false,
	},
	{
		value: 'featured',
		label: 'Featured on Explore',
		sortable: true,
		hidden: false,
	},
	{
		value: 'position',
		label: 'Position',
		sortable: true,
		hidden: false,
	},
]

const PlaylistTable = ({
	playlistQuery,
	playlistState,
	isSelectable,
	selectedItemState,
	renderToolbar,
	...props
}) => {
	const navigate = useNavigate()
	const {
		isLoading,
		isLoaded,
		isEmpty,
		playlists,
		findPlaylists,
		updatePlaylistsPosition,
		changeIsTopPlaylist,
		deletePlaylist,
		paginate,
		query,
		page,
		numPages,
		reloadPlaylists,
		sortKey,
		sortDirection,
		handleSort,
	} = playlistState

	const { selectedIds, handleSelect, handleSelectAll } =
		selectedItemState

	const { handleClick, handleEditClick } = useNavigation({
		navigate,
		url: '/playlists',
	})

	const { showAlertSuccess } = useAlerts()

	const handleDeleteClick = async (playlist) => {
		await deletePlaylist(playlist)
		showAlertSuccess('Playlist has been deleted')
		reloadPlaylists()
	}

	useEffect(() => {
		findPlaylists(
			{
				...playlistQuery,
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection,
			},
			page
		)
	}, [query, page, sortKey, sortDirection])

	const sortedPlaylists =
		sortKey !== 'playlists.position'
			? playlists
			: sortBy(playlists, 'position')

	return (
		isLoaded && (
			<>
				<Paper>
					<Search
						isLoading={isLoading}
						query={query}
						handleSearch={findPlaylists}
					/>
					{!isEmpty ? (
						<TableContainer component={Paper} elevation={0}>
							<Table>
								<TableHead>
									<TableRow>
										{isSelectable && (
											<TableCell>
												<Checkbox
													checked={
														selectedIds?.length ===
														sortedPlaylists?.length
													}
													onChange={() =>
														handleSelectAll(sortedPlaylists)
													}
													color="primary"
												/>
											</TableCell>
										)}
										{TABLE_HEADERS.filter(
											(h) => !h.hidden
										).map((header, idx) => (
											<SortableTableHeader
												key={idx}
												handleSort={handleSort}
												value={`playlists.${header.value}`}
												sortable={header.sortable}
												sortKey={sortKey}
												sortDirection={sortDirection}
											>
												{header.label}
											</SortableTableHeader>
										))}
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sortedPlaylists.map((playlist, idx) => (
										<PlaylistItem
											key={idx}
											playlist={playlist}
											isSelectable={isSelectable}
											selectedIds={selectedIds}
											handleSelect={handleSelect}
											handleClick={handleClick}
											handleEditClick={handleEditClick}
											changeIsTopPlaylist={
												changeIsTopPlaylist
											}
											sortKeyIsPosition={
												sortKey === 'playlists.position'
											}
											updatePlaylistsPosition={
												updatePlaylistsPosition
											}
											handleDeleteClick={handleDeleteClick}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Placeholder
							icon={<SearchIcon />}
							title="No playlists"
							subtitle="Please try another search"
						/>
					)}
				</Paper>
				{!isEmpty && (
					<Box my={1}>
						<Pagination
							onChange={(ev, page) => paginate(page)}
							color="primary"
							size="small"
							shape="rounded"
							page={page}
							count={numPages}
						/>
					</Box>
				)}
				{renderToolbar ? renderToolbar() : null}
			</>
		)
	)
}

export default PlaylistTable

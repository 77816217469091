import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const useQuery = ({ handleSearch }) => {
	const location = useLocation()
	const searchParams = queryString.parse(location.search)

	const [query, setQuery] = useState(searchParams)

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		let newQuery = {
			...query,
			[name]: value,
		}
		setQuery(newQuery)
		if (value === '') {
			handleSearch(newQuery)
		}
	}

	const handleKeyPress = (event) => {
		console.log(event)
		switch (event.key) {
			case 'Enter':
				handleSearch(query)
				return
			default:
				return
		}
	}

	const handleClear = () => {
		setQuery({ search: '' })
		handleSearch({
			search: '',
			...query,
		})
	}

	return {
		query,
		handleClear,
		handleChange,
		handleKeyPress,
		setQuery,
	}
}

export default useQuery

import React, { useEffect, useState } from 'react'
import api from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useNavigate } from 'react-router'

const useMarketingTile = () => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const navigate = useNavigate()

	const [isLoaded, setIsLoaded] = useState(false)
	const [marketingTile, setMarketingTile] = useState(null)
	const [errors, setErrors] = useState([])

	const findMarketingTile = async () => {
		try {
			showLoading()
			setIsLoaded(false)
			const res = await api.get(`/admin/marketing_tile`)
			setMarketingTile(res.data)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const reloadMarketingTile = () => findMarketingTile()

	const createMarketingTile = async (marketing_tile) => {
		try {
			showLoading()
			const res = await api.post(`/admin/marketing_tile`, {
				marketing_tile,
			})
			if (res.data) {
				setMarketingTile(res.data)
				setIsLoaded(true)
			}
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const updateMarketingTile = async (marketing_tile) => {
		try {
			showLoading()
			const res = await api.put(`/admin/marketing_tile`, {
				marketing_tile,
			})
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteMarketingTile = async (marketing_tile) => {
		try {
			showLoading()
			const res = await api.delete(`/admin/marketing_tile`)
			setMarketingTile(null)
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const uploadFile = async (file, attributeName) => {
		try {
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
			let formData = new FormData()
			formData.append(
				`marketing_tile[${attributeName}]`,
				file
			)
			const res = await api.post(
				`/admin/marketing_tile/upload_file`,
				formData,
				config
			)
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteFile = async (type) => {
		showLoading()
		await api.post(`/admin/marketing_tile/delete_file`, {
			type,
		})
		hideLoading()
	}

	const changePublished = async (value) => {
		try {
			const res = await api.put(
				`/admin/marketing_tile`,
				{
					marketing_tile: { published: !!value },
				}
			)
			reloadMarketingTile()
		} catch (e) {
			handleErrors(e)
		}
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setMarketingTile({
			...marketingTile,
			[name]: value,
		})
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('useMarketingTile Error:', e)
	}

	useEffect(() => {
		findMarketingTile()
	}, [])

	return {
		isLoading,
		isLoaded,
		marketingTile,
		errors,
		findMarketingTile,
		reloadMarketingTile,
		updateMarketingTile,
		createMarketingTile,
		deleteMarketingTile,
		changePublished,
		handleChange,
		uploadFile,
		deleteFile,
	}
}

export default useMarketingTile

import { blue } from 'theme/colors'
import palette from 'theme/palette'

export const selectStyles = {
	select: (styles) => ({
		...styles,
	}),
	control: (styles) => ({
		...styles,
		width: '100%',
		color: '#000 !important',
		fontSize: '14px',
		marginTop: '8px',
		fontFamily: 'Poppins',
		padding: '0',
		borderRadius: '4px !important',
	}),
	option: (
		styles,
		{ data, isDisabled, isFocused, isSelected }
	) => ({
		...styles,
		backgroundColor: 'white',
		fontSize: '14px',
		fontFamily: 'Poppins',
		color: 'black',
		':hover': {
			backgroundColor: blue,
			color: 'white',
		},
	}),
	noOptionsMessage: (styles) => ({
		...styles,
		fontSize: '14px',
		fontFamily: 'Poppins',
	}),
	input: (styles) => ({
		...styles,
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: '14px',
		fontFamily: 'Poppins',
		color: palette.text.secondary,
	}),
	singleValue: (styles) => ({
		...styles,
		color: '#222222',
		borderRadius: '4px',
	}),
	multiValue: (styles) => ({
		...styles,
		padding: '2px 6px',
		borderRadius: '4px',
		backgroundColor: blue,
	}),
	multiValueLabel: (styles) => ({
		...styles,
		backgroundColor: blue,
		color: 'white',
	}),
	multiValueRemove: (styles) => ({
		...styles,
		color: 'white',
		cursor: 'pointer',
		':hover': {
			backgroundColor: blue,
			color: 'white',
		},
	}),
}

import React from 'react'
import {
	Grid,
	makeStyles,
} from '@material-ui/core'
import { SwitchInput, TextInput, AsyncAutosuggest } from 'components'

const useStyles = makeStyles(() => ({
	linkDescription: {
		marginTop: 6,
	},
}))

const PromoPlaylistForm = ({
	promoPlaylist,
	handleChange,
}) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={promoPlaylist.title}
					label="Title"
					name="title"
					placeholder="Title"
					handleChange={handleChange}
				/>
				<TextInput
					value={promoPlaylist.subtitle}
					label="Subtitle"
					name="subtitle"
					placeholder="Subtitle"
					handleChange={handleChange}
				/>
				<AsyncAutosuggest
					value={promoPlaylist.playlist_id}
					label="Playlist"
					name="playlist_id"
					placeholder="Playlist"
					remoteUrl="/admin/playlists"
					displayField="name"
					handleChange={handleChange}
				/>
        <SwitchInput
					value={promoPlaylist.published === true}
					label="Published"
					name="published"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default PromoPlaylistForm

import React, { useEffect, useState } from 'react'
import { useAlerts, useAuth } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Box,
	Button,
	Container,
	Grid,
} from '@material-ui/core'
import { TextInput } from 'components'

const ResetPassword = ({ ...props }) => {
	const params = useParams()
	const navigate = useNavigate()

	const [password, setPassword] = useState()
	const [pin, setPin] = useState()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const { resetPassword } = useAuth()

	useEffect(() => {
		if (params.pin) {
			setPin(params.pin)
		} else {
			showAlertError('Password reset pin is not valid')
		}
	}, [params.pin])

	const handleSubmit = async () => {
		await resetPassword(password, pin)
	}

	const handleChange = ({ target }) =>
		setPassword(target.value)

	return (
		<Container maxWidth="sm">
			<Box my={10}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextInput
							fullWidth
							name="password"
							label="Password"
							handleChange={handleChange}
							type="password"
							placeholder="New password"
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							size="large"
							onClick={handleSubmit}
							variant="contained"
							color="primary"
						>
							Reset your password
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default ResetPassword

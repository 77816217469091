import React from 'react'
import { Avatar } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
	},
}))

const UserAvatar = ({ avatar, size = 40 }) => {
	const classes = useStyles()
	return (
		<StyledAvatar
			className={classes.avatar}
			size={size}
			src={avatar}
		>
			<Person />
		</StyledAvatar>
	)
}

export default UserAvatar

const StyledAvatar = styled(Avatar)`
  width: ${(props) => `${props.size}px;`}
  height: ${(props) => `${props.size}px;`}
`

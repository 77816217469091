import React from 'react'
import { useNavigate } from 'react-router'
import { useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Container,
} from '@material-ui/core'
import { Subheader } from 'components'
import DiscoverOverrideDetails from 'containers/discoverOverride/DiscoverOverrideDetails'
import { useDiscoverOverride } from 'hooks'

const DiscoverOverrideShow = ({ ...props }) => {
	const navigate = useNavigate()

	const {
		isLoading,
		isLoaded,
		discoverOverride,
		removeDiscoverOverride,
		changePublished,
	} = useDiscoverOverride()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleCreateClick = async () =>
		navigate(`/discover_override/new`)

	const handleEditClick = () =>
		navigate(`/discover_override/edit`)

	const handleRemoveClick = async (discoverOverride) => {
		await removeDiscoverOverride()
	}

	const togglePublished = (ev) =>
		changePublished(!discoverOverride.published)

	if (!isLoading && !discoverOverride) {
		return (
			<>
				<Subheader
					title="Create Discover Override"
					buttons={[
						<Button
							onClick={handleCreateClick}
							color="primary"
							variant="contained"
						>
							Create
						</Button>,
					]}
				/>
			</>
		)
	}

	return (
		<>
			<Subheader
				title={
					isLoading ? 'Loading...' : 'Discover Override'
				}
				buttons={[
					<Button
						onClick={handleEditClick}
						color="primary"
						variant="contained"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<DiscoverOverrideDetails
							discoverOverride={discoverOverride}
							togglePublished={togglePublished}
						/>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default DiscoverOverrideShow

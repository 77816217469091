import React, { useContext } from 'react'
import { AppContext } from 'context'
import {
	Backdrop,
	CircularProgress,
	makeStyles,
} from '@material-ui/core'

// Inspired by the Facebook spinners.
const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	backdrop: {
		backgroundColor: 'rgb(255, 255, 255, 0.5)',
		zIndex: 9999,
	},
}))

const Loader = (props) => {
	const classes = useStyles()
	const { isLoading } = useContext(AppContext)
	return (
		<Backdrop className={classes.backdrop} open={isLoading}>
			<div className={classes.root}>
				<CircularProgress
					value={100}
					size={80}
					thickness={5}
					{...props}
				/>
			</div>
		</Backdrop>
	)
}
export default Loader

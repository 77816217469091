import React, { useEffect } from 'react'
import {
	usePlaylists,
	useAlerts,
	useNavigation,
	useSelected,
} from 'hooks'
import { Subheader } from 'components'
import { Container, Button } from '@material-ui/core'
import PlaylistToolbar from 'containers/playlists/PlaylistToolbar'
import PlaylistTable from './PlaylistTable'

const CarouselAddPlaylist = ({
	title,
	playlistQuery,
	addToCarousel,
	goBack,
	...props
}) => {
	const playlistState = usePlaylists({})
	const { findPlaylists, reloadPlaylists } = playlistState

	useEffect(() => {
		const run = async () => {
			await findPlaylists(playlistQuery)
		}
		run()
	}, [])

	const selectedItemState = useSelected()
	const {
		selected,
		selectedIds,
		handleClear,
		totalSelected,
	} = selectedItemState

	const { showAlertSuccess } = useAlerts()

	const handleAddToCarousel = async () => {
		await addToCarousel(selectedIds)
		handleClear()
		showAlertSuccess('Added to carousel')
		reloadPlaylists()
	}

	return (
		<>
			<Subheader
				title={title}
				buttons={[
					<Button
						variant="contained"
						onClick={() => goBack()}
						color="primary"
					>
						Done
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				<PlaylistTable
					playlistQuery={playlistQuery}
					playlistState={playlistState}
					isSelectable={true}
					selectedItemState={selectedItemState}
					renderToolbar={() => (
						<PlaylistToolbar
							selected={selected}
							selectedIds={selectedIds}
							handleClear={handleClear}
							totalSelected={totalSelected}
							confirmText="Add to Carousel"
							confirmAction={handleAddToCarousel}
							cancelAction={handleClear}
						/>
					)}
				/>
			</Container>
		</>
	)
}

export default CarouselAddPlaylist

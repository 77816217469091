import React from 'react'
import {
	Box,
	Container,
	Grid,
	IconButton,
	Paper,
	Slide,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'

const Toolbar = ({
	open,
	handleClose,
	children,
	...props
}) => (
	<Slide in={open} direction="down">
		<StyledPaper square elevation={2}>
			<StyledContainer maxWidth="xl">
				<Grid container spacing={1}>
					<Grid item xs={1} />
					<Grid item xs={10}>
						<Box
							display="flex"
							mt={1}
							justifyContent="center"
						>
							{children}
						</Box>
					</Grid>
					<Grid item xs={1}>
						<Box
							display="flex"
							width="100%"
							justifyContent="flex-end"
						>
							<StyledButton onClick={handleClose}>
								<Close />
							</StyledButton>
						</Box>
					</Grid>
				</Grid>
			</StyledContainer>
		</StyledPaper>
	</Slide>
)

export default Toolbar

const StyledPaper = styled(Paper)`
	z-index: 2000;
	background-color: #fff;
	top: 0px;
	left: 0px;
	position: fixed;
	height: 64px;
	width: 100%;
`

const StyledContainer = styled(Container)`
	padding-top: 10px;
`
const StyledButton = styled(IconButton)`
	margin-top: 4px;
`

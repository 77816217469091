import React from 'react'
import { useMenu } from 'hooks'
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox,
} from '@material-ui/core'
import { FullScreenImage, SquareImage } from 'components'
import {
	MoreHoriz,
	PlayCircleFilled,
	MusicOff,
	Person,
	Star,
	StarBorder,
} from '@material-ui/icons'

const TrainerItem = ({
	trainer,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) => handleEditClick(trainer, e)}
			>
				Edit
			</MenuItem>
			<MenuItem
				onClick={(e) => handleDeleteClick(trainer, e)}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	const isSelected = selectedIds.includes(trainer.id)

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(trainer)}
						name="trainer"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					<SquareImage
						src={trainer.avatar_image_url}
						variant="rounded"
						size={80}
					/>
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					<FullScreenImage
						src={trainer.fullscreen_image_url}
						height={80}
					/>
				</Button>
			</TableCell>
			<TableCell>
				<IconButton onClick={() => handleClick(trainer)}>
					{trainer.media_url ? (
						<PlayCircleFilled />
					) : (
						<MusicOff />
					)}
				</IconButton>
			</TableCell>
			<TableCell>
				<IconButton onClick={() => handleClick(trainer)}>
					{trainer.featured ? <Star /> : <StarBorder />}
				</IconButton>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(trainer)}>
					{trainer.name}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default TrainerItem

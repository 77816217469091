import React, { useContext } from 'react'
import {
	IconButton,
	Snackbar,
	SnackbarContent,
	colors,
} from '@material-ui/core'
import { AppContext } from 'context'
import {
	Check,
	Close,
	Error as ErrorIcon,
	Info,
	Warning,
} from '@material-ui/icons'
import { blue } from 'theme/colors'

const variantIcon = {
	success: Check,
	warning: Warning,
	error: ErrorIcon,
	info: Info,
}

const styles = {
	success: {
		backgroundColor: blue,
	},
	error: {
		backgroundColor: colors.pink[700],
	},
	info: {
		backgroundColor: blue,
	},
	warning: {
		backgroundColor: blue,
	},
	variantIcon: {
		fontSize: 20,
		opacity: 0.9,
		marginRight: '10px',
	},
	icon: {
		fontSize: 20,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}

const Alert = ({ ...props }) => {
	const { alert, setAlert } = useContext(AppContext)

	const handleClose = () => setAlert()

	return (
		<Snackbar
			open={alert}
			autoHideDuration={2500}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<SnackbarContent
				style={alert && styles[alert.variant]}
				message={
					<span style={styles.message}>
						{alert && alert.message}
					</span>
				}
				action={[
					<IconButton
						key="close"
						color="inherit"
						onClick={handleClose}
					>
						<Close style={styles.icon} />
					</IconButton>,
				]}
			/>
		</Snackbar>
	)
}

export default Alert

import React, { useEffect } from 'react'
import {
	useRatings,
	useAlerts,
	useNavigation,
	useSelected,
} from 'hooks'
import { useNavigate } from 'react-router-dom'
import {
	Layout,
	SortableTableHeader,
	Column,
	Subheader,
	Page,
	Placeholder,
	Search,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	Tooltip,
	Toolbar,
} from '@material-ui/core'
import {
	Add,
	Search as SearchIcon,
} from '@material-ui/icons'
import RatingItem from 'containers/ratings/RatingItem'
import RatingToolbar from 'containers/ratings/RatingToolbar'

const TABLE_HEADERS = [
	{
		value: 'user_id',
		label: 'User',
		sortable: false,
		hidden: false,
	},
	{
		value: 'audio_id',
		label: 'Audio',
		sortable: false,
		hidden: false,
	},
	{
		value: 'overall_rating',
		label: 'Overall',
		sortable: true,
		hidden: false,
	},
	{
		value: 'trainer_rating',
		label: 'Trainer',
		sortable: true,
		hidden: false,
	},
	{
		value: 'music_rating', //iOS app swappped music for session
		label: 'Session',
		sortable: true,
		hidden: false,
	},
	{
		value: 'session_rating', //iOS app swappped session for music
		label: 'Music',
		sortable: true,
		hidden: false,
	},
]

const RatingList = ({ ...props }) => {
	const navigate = useNavigate()
	const {
		isLoading,
		isLoaded,
		isEmpty,
		ratings,
		findRatings,
		deleteRating,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadRatings,
		sortKey,
		sortDirection,
		handleSort,
	} = useRatings({})

	const handleClick = (rating) => null

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected,
	} = useSelected()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleDeleteClick = async (rating) => {
		await deleteRating(rating)
		showAlertSuccess('Rating has been deleted')
		reloadRatings()
	}

	useEffect(() => {
		findRatings(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection,
			},
			page
		)
	}, [query, page, sortKey, sortDirection])

	const isSelectable = false

	return (
		<>
			<Subheader title="Ratings" />
			<Container maxWidth="lg">
				{isLoaded && (
					<>
						<Paper>
							{false && (
								<Search
									isLoading={isLoading}
									query={query}
									handleSearch={findRatings}
								/>
							)}
							{!isEmpty ? (
								<TableContainer
									component={Paper}
									elevation={0}
								>
									<Table>
										<TableHead>
											<TableRow>
												{isSelectable && (
													<TableCell>
														<Checkbox
															checked={
																selectedIds?.length ===
																ratings?.length
															}
															onChange={() =>
																handleSelectAll(ratings)
															}
															color="primary"
														/>
													</TableCell>
												)}
												{TABLE_HEADERS.filter(
													(h) => !h.hidden
												).map((header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`ratings.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												))}
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{ratings.map((rating, idx) => (
												<RatingItem
													key={idx}
													rating={rating}
													isSelectable={isSelectable}
													selectedIds={selectedIds}
													handleSelect={handleSelect}
													handleClick={handleClick}
													handleDeleteClick={
														handleDeleteClick
													}
												/>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Placeholder
									icon={<SearchIcon />}
									title="No ratings"
									subtitle="Please try another search"
								/>
							)}
						</Paper>
						{!isEmpty && (
							<Box my={1}>
								<Pagination
									onChange={(ev, page) => paginate(page)}
									color="primary"
									size="small"
									shape="rounded"
									page={page}
									count={numPages}
								/>
							</Box>
						)}
					</>
				)}
				<RatingToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	)
}

export default RatingList

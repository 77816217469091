import React from 'react'
import {
	Box,
	Button,
	List,
	Paper,
	Container,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { sortBy } from 'lodash'

import { Subheader } from 'components'
import CuratedListPlaylistItem from 'containers/playlists/CuratedListPlaylistItem'
import { useNavigation, useTopPlaylists } from 'hooks'
import { useNavigate } from 'react-router-dom'

const TopPlaylistsShow = ({ ...props }) => {
	const navigate = useNavigate()

	const { handleAddClick } = useNavigation({
		navigate,
		url: '/curated_lists/top_playlists',
	})

	const {
		topPlaylists = [],
		updateTopPlaylistsPosition,
		removeTopPlaylist,
	} = useTopPlaylists()

	const playlists = sortBy(
		topPlaylists,
		'top_playlists_position',
		'asc'
	)

	return (
		<>
			<Subheader
				title="Top Playlists"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Playlists
					</Button>,
				]}
			/>
			<Container>
				<Box my={4} />
				{playlists && playlists.length > 0 ? (
					<Paper>
						<List>
							{playlists.map((playlist, idx) => (
								<CuratedListPlaylistItem
									key={idx}
									playlist={playlist}
									position={
										playlist.top_playlists_position + 1
									}
									handleMoveUp={async () => {
										await updateTopPlaylistsPosition(
											playlist.id,
											playlist.top_playlists_position - 1
										)
									}}
									handleMoveDown={async () => {
										await updateTopPlaylistsPosition(
											playlist.id,
											playlist.top_playlists_position + 1
										)
									}}
									handleRemoveClick={async () => {
										await removeTopPlaylist(playlist.id)
									}}
								/>
							))}
						</List>
					</Paper>
				) : null}
			</Container>
		</>
	)
}

export default TopPlaylistsShow

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthRoute } from 'components'

//auth
import Login from 'containers/auth/Login'
import ForgotPassword from 'containers/auth/ForgotPassword'
import ResetPassword from 'containers/auth/ResetPassword'
import VerifyPin from 'containers/auth/VerifyPin'

//routes
import UserNew from 'containers/users/UserNew'
import UserShow from 'containers/users/UserShow'
import UserEdit from 'containers/users/UserEdit'
import UserList from 'containers/users/UserList'
import TrainerNew from 'containers/trainers/TrainerNew'
import TrainerShow from 'containers/trainers/TrainerShow'
import TrainerEdit from 'containers/trainers/TrainerEdit'
import TrainerList from 'containers/trainers/TrainerList'
import CuratedListList from 'containers/curatedLists/CuratedListList'
import DailyAffirmationsShow from 'containers/curatedLists/DailyAffirmationsShow'
import DailyAffirmationsAddAudio from 'containers/curatedLists/DailyAffirmationsAddAudio'
import TopPlaylistsShow from 'containers/curatedLists/TopPlaylistsShow'
import TopPlaylistsAddPlaylist from 'containers/curatedLists/TopPlaylistsAddPlaylist'
import MuneTopPicksShow from 'containers/curatedLists/MuneTopPicksShow'
import MuneTopPicksAddAudio from 'containers/curatedLists/MuneTopPicksAddAudio'
import MarketingTileNew from 'containers/marketingTile/MarketingTileNew'
import MarketingTileShow from 'containers/marketingTile/MarketingTileShow'
import MarketingTileEdit from 'containers/marketingTile/MarketingTileEdit'
import DiscoverOverrideNew from 'containers/discoverOverride/DiscoverOverrideNew'
import DiscoverOverrideShow from 'containers/discoverOverride/DiscoverOverrideShow'
import DiscoverOverrideEdit from 'containers/discoverOverride/DiscoverOverrideEdit'
import PromoPlaylistShow from 'containers/promoPlaylist/PromoPlaylistShow'
import PromoPlaylistEdit from 'containers/promoPlaylist/PromoPlaylistEdit'
import PlaylistNew from 'containers/playlists/PlaylistNew'
import PlaylistShow from 'containers/playlists/PlaylistShow'
import PlaylistEdit from 'containers/playlists/PlaylistEdit'
import PlaylistList from 'containers/playlists/PlaylistList'
import FavoriteList from 'containers/favorites/FavoriteList'
import RatingList from 'containers/ratings/RatingList'
import AudioNew from 'containers/audios/AudioNew'
import AudioShow from 'containers/audios/AudioShow'
import AudioEdit from 'containers/audios/AudioEdit'
import AudioList from 'containers/audios/AudioList'
import VibeNew from 'containers/vibes/VibeNew'
import VibeEdit from 'containers/vibes/VibeEdit'
import VibeList from 'containers/vibes/VibeList'
import VibeShow from 'containers/vibes/VibeShow'

const AllRoutes = () => (
	<Routes>
		<Route path="/" element={<Login />} exact />
		<Route path="/login/:token" element={<Login />} />
		<Route path="/login" element={<Login />} />
		<Route
			path="/forgot_password"
			element={<ForgotPassword />}
			exact
		/>
		<Route path="/verify_pin" component={VerifyPin} exact />
		<Route
			path="/reset_password/:pin"
			element={<ResetPassword />}
			exact
		/>
		<Route
			path="/users/passwords/edit"
			element={<ResetPassword />}
			exact
		/>
		<Route
			path="/sessions/new"
			element={
				<AuthRoute>
					<AudioNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/sessions/:id/edit"
			element={
				<AuthRoute>
					<AudioEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/sessions"
			element={
				<AuthRoute>
					<AudioList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/sessions/:id"
			element={
				<AuthRoute>
					<AudioShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/rating"
			element={
				<AuthRoute>
					<RatingList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/favorites"
			element={
				<AuthRoute>
					<FavoriteList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists"
			element={
				<AuthRoute>
					<CuratedListList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists/daily_affirmations"
			element={
				<AuthRoute>
					<DailyAffirmationsShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists/daily_affirmations/new"
			element={
				<AuthRoute>
					<DailyAffirmationsAddAudio />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists/top_playlists"
			element={
				<AuthRoute>
					<TopPlaylistsShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists/top_playlists/new"
			element={
				<AuthRoute>
					<TopPlaylistsAddPlaylist />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists/mune_top_picks"
			element={
				<AuthRoute>
					<MuneTopPicksShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/curated_lists/mune_top_picks/new"
			element={
				<AuthRoute>
					<MuneTopPicksAddAudio />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/marketing_tile"
			element={
				<AuthRoute>
					<MarketingTileShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/marketing_tile/new"
			element={
				<AuthRoute>
					<MarketingTileNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/marketing_tile/edit"
			element={
				<AuthRoute>
					<MarketingTileEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/discover_override"
			element={
				<AuthRoute>
					<DiscoverOverrideShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/discover_override/new"
			element={
				<AuthRoute>
					<DiscoverOverrideNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/discover_override/edit"
			element={
				<AuthRoute>
					<DiscoverOverrideEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/playlists/new"
			element={
				<AuthRoute>
					<PlaylistNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/playlists/:id/edit"
			element={
				<AuthRoute>
					<PlaylistEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/playlists"
			element={
				<AuthRoute>
					<PlaylistList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/promo_playlist"
			element={
				<AuthRoute>
					<PromoPlaylistShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/promo_playlist/edit"
			element={
				<AuthRoute>
					<PromoPlaylistEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/playlists/:id"
			element={
				<AuthRoute>
					<PlaylistShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/trainers/new"
			element={
				<AuthRoute>
					<TrainerNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/trainers/:id/edit"
			element={
				<AuthRoute>
					<TrainerEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/trainers"
			element={
				<AuthRoute>
					<TrainerList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/trainers/:id"
			element={
				<AuthRoute>
					<TrainerShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/vibes/new"
			element={
				<AuthRoute>
					<VibeNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/vibes/:id/edit"
			element={
				<AuthRoute>
					<VibeEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/vibes" 
			element={
				<AuthRoute>
					<VibeList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/vibes/:id"
			element={
				<AuthRoute>
					<VibeShow />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/users/new"
			element={
				<AuthRoute>
					<UserNew />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/users/:id/edit"
			element={
				<AuthRoute>
					<UserEdit />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/users"
			element={
				<AuthRoute>
					<UserList />
				</AuthRoute>
			}
			exact
		/>
		<Route
			path="/users/:id"
			element={
				<AuthRoute>
					<UserShow />
				</AuthRoute>
			}
			exact
		/>
	</Routes>
)

export default AllRoutes

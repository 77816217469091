import React, { useEffect, useState } from 'react'
import { Box, Hidden, IconButton } from '@material-ui/core'
import { Close, Menu } from '@material-ui/icons'
import { Drawer } from 'components'
import styled from 'styled-components'

const Header = (props) => {
	const [open, setOpen] = useState(false)
	const toggleMenu = () => setOpen(!open)

	return (
		<>
			<Hidden mdUp>
				<StyledBox>
					<IconButton
						onClick={toggleMenu}
						color="secondary"
					>
						{open ? <Close /> : <Menu />}
					</IconButton>
				</StyledBox>
			</Hidden>
			<Drawer open={open} toggleMenu={toggleMenu} />
		</>
	)
}

export default Header

const StyledBox = styled(Box)`
	position: fixed;
	top: 10px;
	left: 10px;
`

import React from 'react'
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'
import {
	FullScreenImage,
	HeroImage,
	SquareImage,
	SwitchInput,
} from 'components'
import ReactAudioPlayer from 'react-audio-player'
import { CLASS_TYPES } from './class_type'
import { AUDIO_IMAGE_KEYS } from 'lib/constants'
import { MEMBERSHIP_TYPES } from './membership_type'
import InstagramImage from 'components/images/InstagramImage'

// fullscreen_image_url
// hero_image_url
// vibe_image_url
// trainer_avatar_image_url
// trainer_vibe_image_url

const AudioDetails = ({ audio, togglePublished, toggleFeatured }) => (
	<Grid container spacing={1}>
		<Grid container spacing={1}>
			<Grid item xs={12} lg={4}>
				<Box p={4}>
					<center>
						<Typography variant="body2">
							Fullscreen Img
						</Typography>
						<FullScreenImage
							src={audio.fullscreen_image_url}
							height={420}
						/>
					</center>
				</Box>
			</Grid>
			<Grid item xs={12} lg={8}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={8}>
						<Box p={3}>
							<center>
								<Typography variant="body2">
									Hero Img
								</Typography>
								<HeroImage
									src={audio.hero_image_url ?? ''}
									size={160}
								/>
							</center>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box p={3}>
							<center>
								<Typography variant="body2">
									Instagram Img
								</Typography>
								<InstagramImage
									src={audio.instagram_image_url ?? ''}
									height={160}
								/>
							</center>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box p={3}>
							<center>
								<Typography variant="body2">
									Vibe Img (Sq.)
								</Typography>
								<SquareImage
									src={audio.vibe_image_url ?? ''}
									variant="rounded"
									size={160}
								/>
							</center>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box p={3}>
							<center>
								<Typography variant="body2">
									Trainer Img (Sq.)
								</Typography>
								<SquareImage
									src={audio.trainer_avatar_image_url ?? ''}
									variant="rounded"
									size={160}
								/>
							</center>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box p={3}>
							<center>
								<Typography variant="body2">
									Trainer Vibe Img (Sq.)
								</Typography>
								<SquareImage
									src={audio.trainer_vibe_image_url ?? ''}
									variant="rounded"
									size={160}
								/>
							</center>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Box>
				<ReactAudioPlayer
					src={audio.media_url || ''}
					controls
				/>
			</Box>
		</Grid>
		<Grid item xs={12} sm={8}>
			<List>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="body2">Name</Typography>
						}
						secondary={
							<Typography variant="body1">
								{audio.name ? audio.name : '-'}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="body2">
								Description
							</Typography>
						}
						secondary={
							<Typography variant="body1">
								{audio.description
									? audio.description
									: '-'}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="body2">
								Class Type
							</Typography>
						}
						secondary={
							<Typography variant="body1">
								{audio.class_type
									? CLASS_TYPES[audio.class_type]
									: '-'}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="body2">
								Membership Type
							</Typography>
						}
						secondary={
							<Typography variant="body1">
								{audio.membership_type
									? MEMBERSHIP_TYPES[audio.membership_type]
									: '-'}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem>
					<Box display="flex" alignItems="center">
						<ListItemText
							primary={
								<Typography variant="body2">
									Vibe
								</Typography>
							}
							secondaryTypographyProps={{component: "span"}}
							secondary={
								<span>
									{audio.vibe && (
										<Box
											height="1.5em"
											width="1.5em"
											marginRight={1}
											style={{
												backgroundColor: audio.vibe?.color,
												border: '1px solid #ddd',
											}}
										/>
									)}
									<Typography variant="body1">
										{audio.vibe ? audio.vibe?.name : '-'}
									</Typography>
								</span>
							}
						/>
					</Box>
				</ListItem>
				<ListItem>
					<Box display="flex" alignItems="center">
						<ListItemText
							primary={
								<Typography variant="body2">
									Vibe Image
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{AUDIO_IMAGE_KEYS.find(
										(obj) => obj.value === audio.image_key
									)?.label ?? '-'}
								</Typography>
							}
						/>
					</Box>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="body2">
								Trainer
							</Typography>
						}
						secondary={
							<Typography variant="body1">
								{audio?.trainer?.name
									? audio?.trainer.name
									: '-'}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={
							<Typography variant="body2">Quote</Typography>
						}
						secondary={
							<Typography variant="body1">
								{audio.quote ? audio.quote : '-'}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem>
					<Box flex="column">
						<ListItemText
							primary={
								<Typography variant="body2">
									Published
								</Typography>
							}
						/>
						<SwitchInput
							component="span"
							value={audio.published === true ? true : false}
							label=""
							name="published"
							handleChange={togglePublished}
						/>
					</Box>
				</ListItem>
				<ListItem>
					<Box flex="column">
						<ListItemText
							primary={
								<Typography variant="body2">
									Featured
								</Typography>
							}
						/>
						<SwitchInput
							component="span"
							value={audio.featured === true ? true : false}
							label=""
							name="featured"
							handleChange={toggleFeatured}
						/>
					</Box>
				</ListItem>
			</List>
		</Grid>
	</Grid>
)

export default AudioDetails

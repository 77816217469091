import React, { useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { DatePicker, SelectInput, TextInput } from 'components'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	inputContainer: {
		marginLeft: 20,
		flex: 1,
	},
}))

const FilterItem = ({
	item,
	currentValue,
	onFilterChange,
}) => {
	const classes = useStyles()

	const {
		title,
		key,
		options,
	} = item

	const TYPES = ['select', 'input', 'datepicker']
	let type = item.type ?? 'select'
	if (!TYPES.includes(item.type)) {
		console.warn(`
			[FilterItem]
			Received unexpected type: ${item.type}
			Allowable types: ${TYPES}
		`)
		type = 'select'
	}

	const handleChange = useCallback(
		(item, selectedValue) => {
			onFilterChange({
				key: item.key,
				value: selectedValue,
			})
		},
		[]
	)

	return (
		<div className={classes.container}>
			<Typography variant="body2" style={{ width: 150 }}>
				{item.title}
			</Typography>
			<div className={classes.inputContainer}>
				{type === 'select' ?
					<SelectInput
						options={item.options}
						value={currentValue}
						handleChange={(event) =>
							handleChange(item, event.target.value)
						}
					/>
				: type === 'datepicker' ?
					<DatePicker
						value={currentValue}
						handleChange={(event) =>
							handleChange(item, event.target.value)
						}
					/>
				:
					<TextInput
						value={currentValue}
						handleChange={(event) =>
							handleChange(item, event.target.value)
						}
						isClearable={true}
					/>
				}
			</div>
		</div>
	)
}

export default FilterItem

import React, { useEffect } from 'react'
import { usePlaylists, useMenu } from 'hooks'
import {
	Button,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core'
import { AsyncAutosuggest, Modal } from 'components'
import styled from 'styled-components'

const PlaylistItem = ({
	open,
	handleClose,
	handleAddToPlaylist,
	handleChange,
	playlistId,
	handleClick,
	...props
}) => {
	const {
		loading,
		isLoaded,
		isEmpty,
		playlists,
		findPlaylists,
	} = usePlaylists({})

	useEffect(() => {
		findPlaylists()
	}, [])

	return (
		<Modal
			open={open}
			title="Add to Playlist"
			handleClose={handleClose}
			component={
				<StyledList>
					{isLoaded &&
						!isEmpty &&
						playlists.map((playlist, i) => (
							<ListItem
								key={i}
								button
								onClick={() => handleClick(playlist)}
							>
								<ListItemText primary={playlist.name} />
							</ListItem>
						))}
				</StyledList>
			}
		/>
	)
}

export default PlaylistItem

const StyledList = styled(List)`
	max-height: 400px;
	overflow-y: scroll;
`

import React from 'react'
import { useNavigate } from 'react-router'
import {
	Box,
	Paper,
	Button,
	Container,
} from '@material-ui/core'
import { Subheader } from 'components'
import PromoPlaylistDetails from 'containers/promoPlaylist/PromoPlaylistDetails'
import { usePromoPlaylist } from 'hooks'

const PromoPlaylistShow = ({ ...props }) => {
	const navigate = useNavigate()

	const {
		isLoading,
		promoPlaylist
	} = usePromoPlaylist()

	const handleEditClick = () =>
		navigate(`/promo_playlist/edit`)

	return (
		<>
			<Subheader
				title={
					isLoading ? 'Loading...' : 'Promo Playlist'
				}
				buttons={[
					<Button
						onClick={handleEditClick}
						color="primary"
						variant="contained"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PromoPlaylistDetails
							promoPlaylist={promoPlaylist}
						/>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default PromoPlaylistShow

import React from 'react'
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'
import { SquareImage, SwitchInput } from 'components'

const MarketingTileDetails = ({
	marketingTile,
	togglePublished,
}) =>
	!marketingTile ? (
		<Typography variant="body2">No promo tile.</Typography>
	) : (
		<Grid container spacing={1}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Box p={4}>
						<center>
							<Typography variant="body2">
								Image (Sq.)
							</Typography>
							<SquareImage
								src={marketingTile.image_url}
								variant="rounded"
								size={420}
							/>
						</center>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={8}>
				<List>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Link URL
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{marketingTile.link_url
										? marketingTile.link_url
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Grid>
			<ListItem>
				<Box flex="column">
					<ListItemText
						primary={
							<Typography variant="body2">
								Published
							</Typography>
						}
					/>
					<SwitchInput
						component="span"
						value={
							marketingTile.published === true
								? true
								: false
						}
						label=""
						name="published"
						handleChange={togglePublished}
					/>
				</Box>
			</ListItem>
		</Grid>
	)

export default MarketingTileDetails

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom';
import { useVibes, useAudios, useAlerts } from 'hooks'
import {
	Box,
	List,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Subheader } from 'components'
import VibeDetails from 'containers/vibes/VibeDetails'
import AudioListItem from 'containers/audios/AudioListItem'

const VibeShow = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const { vibeId, isLoading, isLoaded, vibe, findVibe } =
		useVibes({ id: id })

	const { removeVibe, moveAudioUp, moveAudioDown } =
		useAudios()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleEditClick = () =>
		navigate(`/vibes/${id}/edit`)

	const handleRemoveClick = async (audio) => {
		await removeVibe(audio?.id)
		findVibe(id)
	}

	const handleMoveUp = async (audio) => {
		await moveAudioUp(audio?.id)
		findVibe(id)
	}

	const handleMoveDown = async (audio) => {
		await moveAudioDown(audio?.id)
		findVibe(id)
	}

	useEffect(() => {
		if (id) findVibe(id)
	}, [])

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `${vibe?.name}`}
				buttons={[
					<Button
						variant="contained"
						onClick={handleEditClick}
						color="primary"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<VibeDetails vibe={vibe} />
					</Box>
				</Paper>
				<Box my={4} />
				{vibe?.audios && vibe?.audios.length > 0 ? (
					<Paper>
						<List>
							{vibe?.audios.map((audio, idx) => (
								<AudioListItem
									position={idx + 1}
									handleMoveUp={handleMoveUp}
									handleMoveDown={handleMoveDown}
									handleRemoveClick={handleRemoveClick}
									key={audio.id}
									audio={audio}
								/>
							))}
						</List>
					</Paper>
				) : null}
			</Container>
		</>
	)
}

export default VibeShow

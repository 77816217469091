import { useEffect, useState } from 'react'
import api from 'api'
import { useLoaders } from 'hooks'

const usePromoPlaylist = () => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()

	const [isLoaded, setIsLoaded] = useState(false)
	const [promoPlaylist, setPromoPlaylist] = useState({})
	const [errors, setErrors] = useState([])

	const findPromoPlaylist= async () => {
		try {
			showLoading()
			setIsLoaded(false)
			const res = await api.get(`/admin/promo_playlist`)
			setPromoPlaylist(res.data)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const updatePromoPlaylist = async ({ title, subtitle, playlist_id, published }) => {
		try {
			showLoading()
			const res = await api.put(`/admin/promo_playlist`, {
				promo_playlist: {
					title,
					subtitle,
					playlist_id,
					published,
				},
			})
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value

    setPromoPlaylist((oldValue) => ({
			...oldValue,
			[name]: value,
		}))
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('usePromoPlaylist Error:', e)
	}

	useEffect(() => {
		findPromoPlaylist()
	}, [])

	return {
		isLoading,
		isLoaded,
		promoPlaylist,
		errors,
		findPromoPlaylist,
		updatePromoPlaylist,
		handleChange,
	}
}

export default usePromoPlaylist

import React, { useContext } from 'react'
import { AppContext } from 'context'
import { Navigate } from 'react-router-dom'

const AuthRoute = ({ children }) => {
	const { authenticated } = useContext(AppContext)

	if (!authenticated){
		return <Navigate replace to="/login" />
	}

	return children
}

export default AuthRoute

import React from 'react'
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'

const PromoPlaylistDetails = ({
	promoPlaylist
}) =>
		(<Grid container spacing={1}>
			<Grid item xs={12} sm={8}>
				<List>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Title
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{promoPlaylist.title
										? promoPlaylist.title
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Subtitle
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{promoPlaylist.subtitle
										? promoPlaylist.subtitle
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Playlist
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{promoPlaylist.playlist?.name
										? promoPlaylist.playlist.name
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<Box flex="column">
							<ListItemText
								primary={
									<Typography variant="body2">
										Published
									</Typography>
								}
								secondary={
									<Typography variant="body1">
										{promoPlaylist.published
											? 'Yes'
											: 'No'}
									</Typography>
								}
							/>
						</Box>
					</ListItem>
				</List>
			</Grid>
		</Grid>)

export default PromoPlaylistDetails

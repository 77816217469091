import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import {
	Button,
	LinearProgress,
	Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import { InsertDriveFile } from '@material-ui/icons'
import { blue } from 'theme/colors'

const styles = {
	icon: {
		height: '50px',
		width: '50px',
		color: blue,
	},
	button: {
		fontSize: '12px',
	},
}

const FileUploader = ({
	fileUrl,
	handleUpload,
	handleDelete,
	...props
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [file, setFile] = useState()
	const [files, setFiles] = useState([])
	const [isUploading, setIsUploading] = useState(false)
	const [isUploaded, setIsUploaded] = useState(false)
	const [dropzoneActive, setDropzoneActive] =
		useState(false)

	const handleEditClick = () => setIsEditing(!isEditing)

	const onDrop = async (files) => {
		setIsUploading(true)
		setIsUploaded(false)
		await handleUpload(files[0])
		setIsEditing(false)
		setIsUploading(false)
		setIsUploaded(true)
		setFiles([])
		setFile(null)
	}

	const onDragEnter = (files) => {
		setFiles(files)
		setDropzoneActive(true)
	}

	const onDragLeave = (files) => {
		setFiles(files)
		setDropzoneActive(false)
	}

	const handleDownloadClick = () =>
		window.open(fileUrl, '_blank')

	return (
		<div>
			{fileUrl && !isEditing && (
				<>
					<Button onClick={handleDownloadClick}>
						<InsertDriveFile style={styles.icon} />
					</Button>
					{handleDelete && (
						<Button
							color="primary"
							size="small"
							style={styles.editButton}
							onClick={handleDelete}
						>
							Remove
						</Button>
					)}
				</>
			)}

			{isEditing ||
				(!fileUrl && (
					<StyledDropzone
						disableClick={false}
						onDrop={onDrop}
						onDragEnter={onDragEnter}
						onDragLeave={onDragLeave}
						multiple={false}
						activeStyle={styles.dropzoneActive}
					>
						{({getRootProps, getInputProps}) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p>
										{(!isUploaded && !isUploading && !dropzoneActive) && (
											<Typography
												variant="overline"
												color="primary"
											>
												Upload file
											</Typography>
										)}

										{isUploaded && (
											<Typography
												variant="overline"
												color="primary"
											>
												Upload complete
											</Typography>
										)}

										{(!isUploaded && !isUploading && dropzoneActive) && (
											<Typography
												variant="overline"
												color="primary"
											>
												Drop image
											</Typography>
										)}

										{(!isUploaded && isUploading) && (
											<Typography
												variant="overline"
												color="primary"
											>
												Uploading ...
											</Typography>
										)}

										{isUploading && <LinearProgress />}
									</p>
								</div>
							</section>
						)}
					</StyledDropzone>
				))}
		</div>
	)
}

export default FileUploader

const StyledDropzone = styled(Dropzone)`
	margin: 0;
	border-radius: 4px;
	padding: 38px 20px;
	border: 1px dotted #333;
	color: #555;
	height: 100px;
	background-color: #fff;
	text-align: center;
	margin: 0 0 10px 0;
`

const DeleteButton = styled(Button)`
	font-size: 10px;
	color: #666666;
`

import React from 'react'
import { Button } from '@material-ui/core'
import { Toolbar } from 'components'
import styled from 'styled-components'

const PlaylistToolbar = ({
	selected,
	selectedIds,
	totalSelected,
	handleClear,
	confirmText,
	confirmAction,
	cancelText = 'Cancel',
	cancelAction,
}) => (
	<Toolbar
		open={selectedIds?.length > 0}
		handleClose={handleClear}
	>
		<Button onClick={confirmAction} color="primary">
			{confirmText}
		</Button>
		<Button onClick={cancelAction} color="primary">
			{cancelText}
		</Button>
	</Toolbar>
)

export default PlaylistToolbar

const StyledButton = styled(Button)`
	margin-right: 10px;
`

export const CLASS_TYPES = {
	on_demand: 'On Demand',
	daily_class: 'Daily Class',
	daily_affirmation: 'Daily Affirmation',
}

export const CLASS_TYPE_OPTION_MAP = {
	on_demand: { value: 'on_demand', label: 'On Demand' },
	daily_class: {
		value: 'daily_class',
		label: 'Daily Class',
	},
	daily_affirmation: {
		value: 'daily_affirmation',
		label: 'Daily Affirmation',
	},
}

import React from 'react'
import { Button } from '@material-ui/core'
import { Toolbar } from 'components'
import styled from 'styled-components'

const AudioToolbar = ({
	selected,
	selectedIds,
	totalSelected,
	handleClear,
	handlePublish,
	handleUnpublish,
	togglePlaylistModal,
	cancelAction,
}) => (
	<Toolbar
		open={selectedIds?.length > 0}
		handleClose={handleClear}
	>
		<Button onClick={handlePublish} color="primary">
			Publish
		</Button>
		<Button onClick={handleUnpublish} color="primary">
			Un-Publish
		</Button>
		<Button onClick={togglePlaylistModal} color="primary">
			Add to Playlist
		</Button>
		<Button onClick={cancelAction} color="primary">
			Cancel
		</Button>
	</Toolbar>
)

export default AudioToolbar

const StyledButton = styled(Button)`
	margin-right: 10px;
`

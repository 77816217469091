import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useAudios, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Subheader } from 'components'
import AudioForm from 'containers/audios/AudioForm'

const AudioNew = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const {
		audioId,
		isLoading,
		isLoaded,
		audio,
		handleChange,
		createAudio,
		findAudio,
	} = useAudios({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		let newAudio = await createAudio(audio)
		showAlertSuccess('Session has been created')
		navigate(`/sessions/${newAudio.id}/edit`)
	}

	useEffect(() => {
		if (id) findAudio(id)
	}, [])

	return (
		<>
			<Subheader
				title="Add Session"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Box p={4}>
					<AudioForm
						audio={audio}
						handleChange={handleChange}
					/>
					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save & Continue
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default AudioNew

import { makeStyles } from '@material-ui/styles'
import FilterItem from './FilterItem'
import { Box } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flex: 1,
	},
	filterRow: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 10,
	},
}))

const FilterRow = ({
	filters,
	onFilterChange,
	selectedFilters,
}) => {
	const classes = useStyles()

	return (
		<div className={classes.filterRow}>
			{filters.map((filter, idx) => (
				<div key={idx} className={classes.container}>
					<FilterItem
						key={idx}
						item={filter}
						currentValue={selectedFilters[filter.key] ?? ''}
						onFilterChange={onFilterChange}
					/>

					{idx !== filters.length - 1 && <Box width={50} />}
				</div>
			))}
		</div>
	)
}

export default FilterRow

import { useCallback, useEffect, useState } from 'react'
import api from 'api'

const useMuneTopPicks = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [muneTopPicksAudios, setMuneTopPicksAudios] =
		useState([])

	const reload = useCallback(async () => {
		setIsLoading(true)
		const res = await api.get('/audios/mune_top_picks')
		setMuneTopPicksAudios(res.data ?? [])
		setIsLoading(false)
	}, [])

	const addMuneTopPick = useCallback(async (audioId) => {
		const res = await api.put(`/admin/audios/${audioId}`, {
			audio: { is_mune_top_pick: true },
		})
		reload()
	}, [])

	const addAllMuneTopPicks = useCallback(
		async (audioIds) => {
			const res = await api.post(
				`/admin/audios/set_is_mune_top_pick`,
				{
					audio_ids: audioIds,
				}
			)
			reload()
		},
		[]
	)

	const removeMuneTopPick = useCallback(async (audioId) => {
		const res = await api.put(`/admin/audios/${audioId}`, {
			audio: { is_mune_top_pick: false },
		})
		reload()
	}, [])

	const updateMuneTopPicksPosition = useCallback(
		async (audioId, position) => {
			const res = await api.post(
				`/admin/audios/${audioId}/mune_top_picks_position/${position}`
			)
			reload()
		},
		[]
	)

	useEffect(() => {
		reload()
	}, [])

	return {
		isLoading,
		muneTopPicksAudios,
		addMuneTopPick,
		addAllMuneTopPicks,
		removeMuneTopPick,
		updateMuneTopPicksPosition,
		reload,
	}
}

export default useMuneTopPicks

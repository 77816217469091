import React from 'react'
import {
	List,
	ListItem,
	ListItemText,
	Typography,
	Grid,
	Box,
} from '@material-ui/core'
import { SquareImage } from 'components'
import ReactAudioPlayer from 'react-audio-player'

const VibeDetails = ({ vibe }) => (
	<List>
		<ListItem>
			<SquareImage
				src={vibe.intro_image_url}
				variant="rounded"
				size={240}
			/>
		</ListItem>
		<Grid container>
			<Box>
				<ReactAudioPlayer
					src={vibe.intro_media_url || ''}
					controls
				/>
			</Box>
		</Grid>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Name</Typography>
				}
				secondary={
					<Typography variant="body1">
						{vibe.name ? vibe.name : '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				component="div"
				primary={
					<Typography variant="body2">Color</Typography>
				}
				secondaryTypographyProps={{component: "span"}}
				secondary={
					<Box component="span" display="flex" alignItems="center">
						<Box
							component="span"
							height="1.5em"
							width="1.5em"
							marginRight={1}
							style={{
								backgroundColor: vibe.color,
								border: '1px solid #ddd',
							}}
						/>
						<Typography variant="body1">
							{vibe.color ? vibe.color : '-'}
						</Typography>
					</Box>
				}
			/>
		</ListItem>
	</List>
)

export default VibeDetails

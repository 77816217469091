import React from 'react'
import {
	Checkbox,
	FormControlLabel,
	Switch,
	Grid,
} from '@material-ui/core'
import {
	Autosuggest,
	CheckboxGroupInput,
	RadioInput,
	TextInput,
} from 'components'

const PlaylistForm = ({
	playlist,
	isEditing,
	handleChange,
}) => {
	const handleSwitch = (ev, value) => {
		let event = { target: { name: 'featured', value } }
		handleChange(event)
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={playlist.name}
					label="Name"
					name="name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={playlist.fullscreen_image_url}
					label="Fullscreen Image Url"
					name="fullscreen_image_url"
					placeholder="Fullscreen Image Url"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={playlist.thumbnail_image_url}
					label="Thumbnail Image Url"
					name="thumbnail_image_url"
					placeholder="Thumbnail Image Url"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={playlist.description}
					label="Description"
					name="description"
					placeholder="Description"
					handleChange={handleChange}
					rows={3}
					multiline={true}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<FormControlLabel
					control={
						<Switch
							checked={!!playlist.featured}
							onChange={handleSwitch}
							name="featured"
							color="primary"
						/>
					}
					label="Featured"
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					type="number"
					value={playlist.position + 1}
					label="Position"
					name="position"
					placeholder="Position"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default PlaylistForm

import React, { useEffect, useState } from 'react'
import api from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useNavigate } from 'react-router'

const useTrainers = ({ ...props }) => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const navigate = useNavigate()

	const [isLoaded, setIsLoaded] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)
	const [id, setId] = useState(props.id)
	const [trainer, setTrainer] = useState({ id: props.id })
	const [trainers, setTrainers] = useState([])
	const [meta, setMeta] = useState({})
	const [params, setParams] = useState({})
	const [page, setPage] = useState(1)
	const [perPage, setPerPage] = useState(20)
	const [numPages, setNumPages] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const [errors, setErrors] = useState([])
	const [sortKey, setSortKey] = useState(`trainers.id`)
	const [sortDirection, setSortDirection] = useState('desc')

	const findTrainer = async (id) => {
		if (!id) showAlertError('No ID was provided')
		try {
			showLoading()
			setIsLoaded(false)
			setId(id)
			const res = await api.get(`/admin/trainers/${id}`)
			setTrainer(res.data)
			setMeta(res.meta)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const findTrainers = async (params, page = 1) => {
		try {
			showLoading()
			setParams(params)
			setPage(page)
			setIsLoaded(false)
			const res = await api.get('/admin/trainers', {
				params: {
					...params,
					page: page,
					per_page: perPage,
				},
			})
			setTrainers(res.data)
			if (res.meta) {
				setMeta(res.meta)
				setPage(res.meta.page)
				setNumPages(res.meta.num_pages)
				setTotalCount(res.meta.total_count)
			}
			setIsEmpty(res.data.length > 0 ? false : true)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const createTrainer = async (trainer) => {
		try {
			showLoading()
			const res = await api.post(`/admin/trainers`, {
				trainer,
			})
			if (res.data && res.data.id) {
				setTrainer(res.data)
				setIsLoaded(true)
				setId(res.data.id)
			}
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const updateTrainer = async (trainer) => {
		setId(trainer.id)
		try {
			showLoading()
			const res = await api.put(
				`/admin/trainers/${trainer.id}`,
				{
					trainer,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteTrainer = async (trainer) => {
		try {
			showLoading()
			const res = await api.delete(
				`/admin/trainers/${trainer.id}`
			)
			setTrainer({ data: {} })
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const changeFeatured = async (trainer, value) => {
		try {
			const res = await api.put(
				`/admin/trainers/${trainer.id}`,
				{
					trainer: { featured: !!value },
				}
			)
			if (id) reloadTrainer()
			reloadTrainers()
		} catch (e) {
			handleErrors(e)
		}
	}

	const paginate = (page) => findTrainers(params, page)

	// fullscreen_image_url, avatar_image_url, media_url
	// avatar_image_url_by_vibe_id[:vibe_id]
	const uploadFile = async (file, attributeName) => {
		try {
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
			let formData = new FormData()
			formData.append(`trainer[${attributeName}]`, file)
			const res = await api.post(
				`/admin/trainers/${id}/upload_file`,
				formData,
				config
			)
		} catch (e) {
			handleErrors(e)
		}
	}

	// fullscreen_image_url, avatar_image_url, media_url
	// avatar_image_url_by_vibe_id[:vibe_id]
	const deleteFile = async (type, options = {}) => {
		showLoading()
		await api.post(`/admin/trainers/${id}/delete_file`, {
			type,
			vibe_id: options?.vibeId,
		})
		hideLoading()
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setTrainer({
			...trainer,
			[name]: value,
		})
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('useTrainer Error:', e)
	}

	const reloadTrainer = () => findTrainer(id)
	const reloadTrainers = () => findTrainers(params)

	const handleSort = (sortBy) => {
		sortDirection == 'asc'
			? setSortDirection('desc')
			: setSortDirection('asc')
		setSortKey(sortBy)
		navigate(
			`?sort_key=${sortKey}&sort_direction=${sortDirection}`
		)
	}

	useEffect(() => {
		if (props.id) setId(props.id)
	}, [props.id])

	return {
		id,
		isLoading,
		isLoaded,
		isEmpty,
		trainer,
		trainers,
		errors,
		meta,
		findTrainer,
		findTrainers,
		reloadTrainer,
		reloadTrainers,
		updateTrainer,
		createTrainer,
		deleteTrainer,
		changeFeatured,
		paginate,
		handleChange,
		uploadFile,
		deleteFile,
		params,
		sortKey,
		sortDirection,
		handleSort,
		page,
		numPages,
		perPage,
		totalCount,
	}
}

export default useTrainers

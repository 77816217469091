import React from 'react'
import AspectRatioImage from './AspectRatioImage'

const DEFAULT_WIDTH = 343
const DEFAULT_HEIGHT = 161

const HeroImage = AspectRatioImage({
	defaultWidth: DEFAULT_WIDTH,
	defaultHeight: DEFAULT_HEIGHT,
})

export default HeroImage

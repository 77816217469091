import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useDiscoverOverride, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Subheader } from 'components'
import DiscoverOverrideForm from './DiscoverOverrideForm'
import DiscoverOverrideImage from './DiscoverOverrideImage'

const DiscoverOverrideNew = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const {
		isLoading,
		isLoaded,
		discoverOverride = {},
		handleChange,
		createDiscoverOverride,
		reloadDiscoverOverride,
	} = useDiscoverOverride()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		let newDiscoverOverride = await createDiscoverOverride(
			discoverOverride
		)
		showAlertSuccess('Discover override has been created')
		navigate(`/discover_override/edit`)
	}

	return (
		<>
			<Subheader
				title="Add Discover Override"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Box p={4}>
					<DiscoverOverrideImage discoverOverride={discoverOverride} />

					<DiscoverOverrideForm
						discoverOverride={discoverOverride}
						handleChange={handleChange}
					/>

					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save & Continue
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default DiscoverOverrideNew

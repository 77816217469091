import React from 'react'
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	Switch,
	Typography,
} from '@material-ui/core'

const SwitchInput = ({
	style,
	name,
	value,
	text,
	label,
	handleChange,
}) => (
	<FormControl style={style}>
		<FormGroup>
			{text && (
				<Typography variant="overline">{text}</Typography>
			)}
			<FormControlLabel
				control={
					<Switch
						name={name}
						checked={value}
						onChange={handleChange}
						value="true"
					/>
				}
				label={label}
			/>
		</FormGroup>
	</FormControl>
)

export default SwitchInput

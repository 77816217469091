import React from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import {
	useAlerts,
	useDiscoverOverride,
} from 'hooks'
import {
	Box,
	Paper,
	Button,
	Container,
} from '@material-ui/core'
import { Subheader } from 'components'
import { Check } from '@material-ui/icons'
import DiscoverOverrideForm from './DiscoverOverrideForm'
import DiscoverOverrideImage from './DiscoverOverrideImage'

const DiscoverOverrideEdit = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const {
		isLoading,
		isLoaded,
		discoverOverride = {},
		handleChange,
		updateDiscoverOverride,
		reloadDiscoverOverride,
	} = useDiscoverOverride()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await updateDiscoverOverride(discoverOverride)
		showAlertSuccess('Discover override has been updated')
		navigate(`/discover_override`)
	}

	return (
		<>
			<Subheader title="Edit Discover Override" />
			<Container>
				<Paper>
					<Box p={4}>
						<DiscoverOverrideImage discoverOverride={discoverOverride} />

						<DiscoverOverrideForm
							discoverOverride={discoverOverride}
							handleChange={handleChange}
						/>

						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default DiscoverOverrideEdit

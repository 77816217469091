import React, {
	useEffect,
	useState,
	useContext,
} from 'react'
import { useAlerts, useLoaders } from 'hooks'
import { useNavigate } from 'react-router'
import api from 'api'
import { AudiosContext } from 'context'

const useAudios = (props) => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const { currentPage, setCurrentPage } =
		useContext(AudiosContext)

	const navigate = useNavigate()

	const [isLoaded, setIsLoaded] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)
	const [id, setId] = useState(props?.id)
	const [audio, setAudio] = useState({ id: props?.id })
	const [audios, setAudios] = useState([])
	const [meta, setMeta] = useState({})
	const [params, setParams] = useState({})
	const [perPage, setPerPage] = useState(20)
	const [numPages, setNumPages] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const [errors, setErrors] = useState([])
	const [sortKey, setSortKey] = useState(`audios.id`)
	const [sortDirection, setSortDirection] = useState('desc')

	const findAudio = async (id) => {
		if (!id) showAlertError('No ID was provided')
		try {
			showLoading()
			setIsLoaded(false)
			setId(id)
			const res = await api.get(`/admin/audios/${id}`)
			setAudio(res.data)
			setMeta(res.meta)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const findAudios = async (params, page = 1) => {
		try {
			showLoading()
			setParams(params)
			setCurrentPage(page)
			setIsLoaded(false)
			const res = await api.get('/admin/audios', {
				params: {
					...params,
					page: page,
					per_page: perPage,
				},
			})
			setAudios(res.data)
			if (res.meta) {
				setMeta(res.meta)
				setCurrentPage(res.meta.page)
				setNumPages(res.meta.num_pages)
				setTotalCount(res.meta.total_count)
			}
			setIsEmpty(res.data.length > 0 ? false : true)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const createAudio = async (audio) => {
		try {
			showLoading()
			const res = await api.post(`/admin/audios`, {
				audio,
			})
			if (res.data && res.data.id) {
				setAudio(res.data)
				setIsLoaded(true)
				setId(res.data.id)
			}
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const updateAudio = async (audio) => {
		setId(audio.id)
		try {
			showLoading()
			const res = await api.put(
				`/admin/audios/${audio.id}`,
				{
					audio,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const changeIsMuneTopPick = async (audio, value) => {
		try {
			const res = await api.put(
				`/admin/audios/${audio.id}`,
				{
					audio: { is_mune_top_pick: !!value },
				}
			)
			if (id) reloadAudio()
			reloadAudios()
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteAudio = async (audio) => {
		try {
			showLoading()
			const res = await api.delete(
				`/admin/audios/${audio.id}`
			)
			setAudio({ data: {} })
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const removeTrainer = async (audioId) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/audios/${audioId}/remove_trainer`
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const moveAudioUp = async (audioId) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/audios/${audioId}/move_up`
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const moveAudioDown = async (audioId) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/audios/${audioId}/move_down`
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const changePublished = async (audio, value) => {
		try {
			const res = await api.put(
				`/admin/audios/${audio.id}`,
				{
					audio: { published: !!value },
				}
			)
			if (id) reloadAudio()
			reloadAudios()
		} catch (e) {
			handleErrors(e)
		}
	}

	const bulkPublish = async (audioIds) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/audios/bulk_publish`,
				{
					audio_ids: audioIds,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const bulkUnpublish = async (audioIds) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/audios/bulk_unpublish`,
				{
					audio_ids: audioIds,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const changeFeatured = async (audio, value) => {
		try {
			const res = await api.put(
				`/admin/audios/${audio.id}`,
				{
					audio: { featured: !!value },
				}
			)
			if (id) reloadAudio()
			reloadAudios()
		} catch (e) {
			handleErrors(e)
		}
	}

	const addToPlaylist = async (playlistId, audioIds) => {
		setId(audio.id)
		try {
			showLoading()
			const res = await api.post(
				`/admin/audios/${playlistId}/add_audio`,
				{
					audio_ids: audioIds,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const paginate = (page) => findAudios(params, page)

	// media_url, fullscreen_image_url, carousel_image_url
	const uploadFile = async (file, attributeName) => {
		try {
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
			let formData = new FormData()
			formData.append(`audio[${attributeName}]`, file)
			const res = await api.post(
				`/admin/audios/${id}/upload_file`,
				formData,
				config
			)
		} catch (e) {
			handleErrors(e)
		}
	}

	// media_url, fullscreen_image_url, carousel_image_url
	const deleteFile = async (type) => {
		showLoading()
		await api.post(`/admin/audios/${id}/delete_file`, {
			type,
		})
		hideLoading()
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setAudio({
			...audio,
			[name]: value,
		})
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('useAudio Error:', e)
	}

	const reloadAudio = () => findAudio(id)
	const reloadAudios = () => findAudios(params)

	const handleSort = (sortBy) => {
		sortDirection == 'asc'
			? setSortDirection('desc')
			: setSortDirection('asc')
		setSortKey(sortBy)
		navigate(
			`?sort_key=${sortKey}&sort_direction=${sortDirection}`
		)
	}

	useEffect(() => {
		if (props?.id) setId(props?.id)
	}, [props?.id])

	return {
		id,
		isLoading,
		isLoaded,
		isEmpty,
		audio,
		audios,
		errors,
		meta,
		moveAudioUp,
		moveAudioDown,
		removeTrainer,
		changePublished,
		bulkPublish,
		bulkUnpublish,
		changeFeatured,
		addToPlaylist,
		findAudio,
		findAudios,
		reloadAudio,
		reloadAudios,
		updateAudio,
		changeIsMuneTopPick,
		createAudio,
		deleteAudio,
		paginate,
		handleChange,
		uploadFile,
		deleteFile,
		params,
		sortKey,
		sortDirection,
		handleSort,
		page: currentPage,
		perPage,
		numPages,
		totalCount,
	}
}

export default useAudios

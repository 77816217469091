import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Box,
	Button,
	List,
	Paper,
	Container,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { Subheader } from 'components'
import PlaylistAudioItem from 'containers/audios/PlaylistAudioItem'
import { useNavigation } from 'hooks'
import useDailyAffirmations from 'hooks/useDailyAffirmations'

const DailyAffirmationsShow = ({ ...props }) => {
	const navigate = useNavigate()

	const { handleAddClick } = useNavigation({
		navigate,
		url: '/curated_lists/daily_affirmations',
	})

	const {
		isLoading,
		dailyAffimationAudios = [],
		updateDailyAffirmationsPosition,
		removeDailyAffirmation,
	} = useDailyAffirmations()

	return (
		<>
			<Subheader
				title="Daily Affirmations Carousel"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Audios
					</Button>,
				]}
			/>
			<Container>
				<Box my={4} />
				{dailyAffimationAudios &&
				dailyAffimationAudios.length > 0 ? (
					<Paper>
						<List>
							{dailyAffimationAudios.map((audio, idx) => (
								<PlaylistAudioItem
									key={idx}
									audio={audio}
									position={
										audio.daily_affirmations_position + 1
									}
									handleMoveUp={async () =>
										await updateDailyAffirmationsPosition(
											audio.id,
											audio.daily_affirmations_position - 1
										)
									}
									handleMoveDown={async () =>
										await updateDailyAffirmationsPosition(
											audio.id,
											audio.daily_affirmations_position + 1
										)
									}
									handleRemoveClick={async () =>
										await removeDailyAffirmation(audio.id)
									}
								/>
							))}
						</List>
					</Paper>
				) : null}
			</Container>
		</>
	)
}

export default DailyAffirmationsShow

import React from 'react'
import {
	Grid,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { SwitchInput, TextInput } from 'components'

const useStyles = makeStyles((theme) => ({
	linkDescription: {
		marginTop: 6,
	},
}))

const MarketingTileForm = ({
	marketingTile = {},
	isEditing,
	handleChange,
}) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={marketingTile?.link_url}
					label="Link URL"
					name="link_url"
					placeholder="Link URL"
					handleChange={handleChange}
				/>
				<Typography
					className={classes.linkDescription}
					variant="body2"
				>
					EXAMPLES: <br />
					mune://session/&#60;session-id&#62; <br />
					mune://playlist/&#60;playlist-id&#62;
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12}>
				<SwitchInput
					value={
						marketingTile?.published === true ? true : false
					}
					label="Published"
					name="published"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default MarketingTileForm

import React from 'react'
import { useMenu } from 'hooks'
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Avatar,
	Checkbox,
	Grid,
	Box,
} from '@material-ui/core'
import {
	FullScreenImage,
	PlaylistThumbnailImage,
	SquareImage,
} from 'components'
import {
	ExpandMore,
	ExpandLess,
	MoreHoriz,
	Person,
	Star,
	StarBorder,
} from '@material-ui/icons'
import { resize } from 'lib/cloudinary'

const PlaylistItem = ({
	playlist,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	sortKeyIsPosition,
	updatePlaylistsPosition,
	changeIsTopPlaylist,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) => handleEditClick(playlist, e)}
			>
				Edit
			</MenuItem>
			<MenuItem
				onClick={(e) => handleDeleteClick(playlist, e)}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	const isSelected = selectedIds.includes(playlist.id)

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(playlist)}
						name="playlist"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(playlist)}>
					<Grid container spacing={1}>
						<Box p={1}>
							<FullScreenImage
								src={resize(playlist.fullscreen_image_url, {
									height: 60,
								})}
								height={60}
							/>
						</Box>
						<Box p={1}>
							<PlaylistThumbnailImage
								src={resize(playlist.thumbnail_image_url, {
									height: 40,
								})}
								height={40}
							/>
						</Box>
					</Grid>
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(playlist)}>
					{playlist.name}
				</Button>
			</TableCell>
			<TableCell>
				<IconButton onClick={() => handleClick(playlist)}>
					{playlist.featured ? <Star /> : <StarBorder />}
				</IconButton>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(playlist)}>
					{playlist.position == null
						? null
						: playlist.position + 1}
				</Button>
			</TableCell>
			{sortKeyIsPosition && (
				<TableCell>
					<IconButton
						onClick={() =>
							updatePlaylistsPosition(
								playlist,
								playlist.position + 1
							)
						}
					>
						<ExpandMore />
					</IconButton>
					<IconButton
						onClick={() =>
							updatePlaylistsPosition(
								playlist,
								playlist.position - 1
							)
						}
					>
						<ExpandLess />
					</IconButton>
				</TableCell>
			)}
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default PlaylistItem

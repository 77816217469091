import React from 'react'
import {
	Button,
	TableHead,
	TableCell,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import styled from 'styled-components'

const SortableTableHeader = ({
	value,
	sortKey,
	sortable = true,
	sortDirection,
	handleSort,
	children,
}) => {
	let isActive = sortKey === value ? true : false

	const renderIcon = () => {
		if (!sortable || !isActive) return null
		return sortDirection == 'asc' ? (
			<ExpandMore />
		) : (
			<ExpandLess />
		)
	}

	return (
		<TableCell align="left" sortDirection="asc">
			<StyledButton
				color="primary"
				active={(sortKey === value).toString()}
				endIcon={renderIcon()}
				onClick={() => sortable && handleSort(value)}
			>
				{children}
			</StyledButton>
		</TableCell>
	)
}

export default SortableTableHeader

const StyledButton = styled(Button)``

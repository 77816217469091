import React from 'react'
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
} from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'

const CuratedListItem = ({ name, onClick }) => {
	return (
		<TableRow>
			<TableCell>
				<Button onClick={onClick}>{name}</Button>
			</TableCell>
		</TableRow>
	)
}

export default CuratedListItem

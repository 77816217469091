import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'

const SelectInput = ({
	label,
	name,
	value = '',
	options,
	handleChange,
	...props
}) => (
	<FormControl fullWidth variant="outlined">
		{label && (
			<Typography variant="overline" gutterBottom>
				{label}
			</Typography>
		)}
		<Select
			native
			{...props}
			margin="dense"
			value={value}
			name={name}
			onChange={(e) => handleChange(e)}
			input={<OutlinedInput name={name} />}
		>
			<option value="" />
			{options &&
				options.map((option, idx) => (
					<option value={option.value} key={idx}>
						{option.text}
					</option>
				))}
		</Select>
	</FormControl>
)

export default SelectInput

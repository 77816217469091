import React from 'react'
import { useMenu } from 'hooks'
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox,
	Box,
} from '@material-ui/core'
import { SquareImage } from 'components'
import {
	MoreHoriz,
	PlayCircleFilled,
	MusicOff,
} from '@material-ui/icons'
import { resize } from 'lib/cloudinary'

const VibeItem = ({
	vibe,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, handleToggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem onClick={(e) => handleEditClick(vibe, e)}>
				Edit
			</MenuItem>
			<MenuItem onClick={(e) => handleDeleteClick(vibe, e)}>
				Delete
			</MenuItem>
		</Menu>
	)

	const isSelected = selectedIds.includes(vibe.id)

	const imageUrl = resize(vibe.intro_image_url, {
		height: 80,
	})

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(vibe)}
						name="vibe"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(vibe)}>
					<SquareImage
						src={imageUrl}
						variant="rounded"
						size={80}
					/>
				</Button>
			</TableCell>
			<TableCell>
				<IconButton onClick={() => handleClick(vibe)}>
					{vibe.intro_media_url ? (
						<PlayCircleFilled />
					) : (
						<MusicOff />
					)}
				</IconButton>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(vibe)}>
					{vibe.name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(vibe)}>
					<Box
						height={16}
						width={16}
						marginRight={1}
						style={{
							backgroundColor: vibe.color,
							border: '1px solid #ddd',
						}}
					/>
					{vibe.color}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default VibeItem

import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlerts, useLoaders } from 'hooks'
import api from 'api'
import { AppContext } from 'context'

const useAuth = () => {
	const navigate = useNavigate()

	const { showLoading, hideLoading } = useLoaders()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const {
		authenticated,
		setAuthenticated,
		currentUser,
		setCurrentUser,
		setToken,
	} = useContext(AppContext)

	const [user, setUser] = useState({})

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setUser({
			...user,
			[name]: value,
		})
	}

	const fetchMe = async () => {
		try {
			let resp = await api.get('/users/me')
			if (resp && resp.data) {
				setCurrentUser(resp.data)
			}
			return resp.data
		} catch (e) {
			console.log(e)
		}
	}

	const updateMe = async (user) => {
		showLoading()
		let resp = await api.put(`/users/${user.id}`, {
			user: user,
		})
		setCurrentUser(resp.data)
		hideLoading()
		return resp.data
	}

	const loginUser = async (user) => {
		try {
			showLoading()
			let resp = await api.post('/login', {
				user: user,
			})
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				await setTokens(resp.data)
				window.location.href = '/users'
				return resp.data
			} else {
				return false
			}
			hideLoading()
		} catch (e) {
			showAlertError('Your email or password is incorrect')
			hideLoading()
		}
	}

	const signupUser = async (user) => {
		try {
			showLoading()
			let resp = await api.post('/signup', {
				user: user,
			})
			hideLoading()
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				setTokens(resp.data)
				return resp.data
			} else {
				return false
			}
		} catch (e) {
			showAlertError(
				'A user with this email may already exist'
			)
			hideLoading()
		}
	}

	const resetPassword = async (password, pin) => {
		try {
			showLoading()
			let resp = await api.post('/reset_password', {
				pin,
				password,
			})
			hideLoading()
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				setTokens(resp.data)
				window.location.href = '/users'
				return resp.data
			} else {
				return false
			}
		} catch (e) {
			showAlertError(
				'There was an error resetting your password'
			)
			hideLoading()
		}
	}

	const verifyPin = async (pin) => {
		let verified = false
		try {
			showLoading()
			let resp = await api.post('/verify_pin', {
				pin,
			})
			if (resp && resp.data === true) {
				verified = true
			}
			hideLoading()
			return verified
		} catch (e) {
			hideLoading()
			return verified
		}
	}

	const forgotPassword = async (email) => {
		try {
			showLoading()
			let resp = await api.post('/forgot_password', {
				email,
			})
			setCurrentUser(resp.data)
			hideLoading()
			return resp.data
		} catch (e) {
			showAlertError(
				'A user with that email could not be found'
			)
			hideLoading()
		}
	}

	const logoutUser = () => {
		setCurrentUser(null)
		setAuthenticated(null)
		localStorage.removeItem('token')
		window.location.href = '/'
	}

	const authenticateFromToken = (token) => {
		if (token) {
			setAuthenticated(true)
			setToken(token)
		} else {
			return false
		}
	}

	const setTokens = (user) => {
		setToken(user.token)
		localStorage.setItem('token', user.token)
	}

	useEffect(() => {
		if (currentUser && !authenticated) {
			setTokens(currentUser)
			setAuthenticated(true)
		}
	}, [currentUser])

	return {
		user,
		handleChange,
		fetchMe,
		updateMe,
		loginUser,
		signupUser,
		logoutUser,
		verifyPin,
		resetPassword,
		forgotPassword,
		authenticateFromToken,
	}
}

export default useAuth

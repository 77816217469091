export const createAudioFilters = (vibes, trainers) => {
	return [
		{
			type: 'select',
			title: 'Trainer',
			key: 'audios.trainer_id',
			options: trainers?.map((t) => ({
				value: t.id,
				text: t.name,
			})) ?? [],
		},
		{
			type: 'select',
			title: 'Class Type',
			key: 'audios.class_type',
			options: [
				{ value: 'daily_class', text: 'Daily Class' },
				{ value: 'on_demand', text: 'On Demand' },
			],
		},
		{
			type: 'select',
			title: 'Vibe',
			key: 'audios.vibe_id',
			options: vibes?.map((v) => ({
				value: v.id,
				text: v.name,
			})) ?? [],
		},
		{
			type: 'select',
			title: 'Published',
			key: 'audios.published',
			options: [
				{ value: 'true', text: 'True' },
				{ value: 'false', text: 'False' },
			],
		},
		{
			type: 'datepicker',
			title: 'Created Before',
			key: 'audios.created_at.lte',
		},
		{
			type: 'datepicker',
			title: 'Created After',
			key: 'audios.created_at.gte',
		},
		{
			type: 'input',
			title: 'Minimum Duration (seconds)',
			key: 'audios.seconds.gte',
		},
		{
			type: 'input',
			title: 'Maximum Duration (seconds)',
			key: 'audios.seconds.lte',
		},
		{
			type: 'select',
			title: 'Featured',
			key: 'audios.featured',
			options: [
				{ value: 'true', text: 'True' },
				{ value: 'false', text: 'False' },
			],
		},
	]
}

export const AUDIO_TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image',
		sortable: false,
		hidden: false,
	},
	{
		value: 'media_url',
		label: 'Audio',
		sortable: false,
		hidden: false,
	},
	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false,
	},
	{
		value: 'class_type',
		label: 'Class Type',
		sortable: true,
		hidden: false,
	},
	{
		value: 'membership_type',
		label: 'Membership Type',
		sortable: true,
		hidden: false,
	},
	{
		value: 'vibe',
		label: 'Vibe',
		sortable: true,
		hidden: false,
	},
	{
		value: 'trainer_id',
		label: 'Trainer',
		sortable: true,
		hidden: false,
	},
	{
		value: 'published',
		label: 'Published',
		sortable: false,
		hidden: false,
	},
	{
		value: 'featured',
		label: 'Featured',
		sortable: false,
		hidden: false,
	},
]

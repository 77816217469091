import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@material-ui/core'
import { Subheader } from 'components'
import CuratedListItem from './CuratedListItem'
import { useAlerts, useMenu } from 'hooks'
import { Menu, MenuItem } from '@material-ui/core'

const CuratedListList = ({ ...props }) => {
	const navigate = useNavigate()

	const curatedLists = [
		{
			name: 'Daily Affirmations',
			path: 'curated_lists/daily_affirmations',
		},
		{
			name: 'Top Playlists',
			path: 'curated_lists/top_playlists',
		},
		{
			name: 'MUNE Top Picks',
			path: 'curated_lists/mune_top_picks',
		},
	]

	return (
		<>
			<Subheader title="Curated Lists" />
			<Container maxWidth="lg">
				<Paper>
					<TableContainer component={Paper} elevation={0}>
						<Table>
							<TableBody>
								{curatedLists.map((curated_list, idx) => (
									<CuratedListItem
										key={idx}
										name={curated_list.name}
										onClick={() =>
											navigate(curated_list.path)
										}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Container>
		</>
	)
}

export default CuratedListList

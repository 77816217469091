import React from 'react'
import {
	Box,
	Checkbox,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'
import {
	FullScreenImage,
	PlaylistThumbnailImage,
} from 'components'

const PlaylistDetails = ({
	playlist,
	changeIsTopPlaylist,
}) => (
	<List>
		<ListItem>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6}>
					<Box p={1}>
						<center>
							<Typography variant="body2">
								Fullscreen Img
							</Typography>
							<FullScreenImage
								src={playlist.fullscreen_image_url}
								variant="rounded"
								height={360}
							/>
						</center>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Box p={1}>
						<center>
							<Typography variant="body2">
								Thumbnail Img
							</Typography>
							<PlaylistThumbnailImage
								src={playlist.thumbnail_image_url}
								variant="rounded"
								height={240}
							/>
						</center>
					</Box>
				</Grid>
			</Grid>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Name</Typography>
				}
				secondary={
					<Typography variant="body1">
						{playlist.name ? playlist.name : '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">
						Description
					</Typography>
				}
				secondary={
					<Typography variant="body1">
						{playlist.description
							? playlist.description
							: '-'}
					</Typography>
				}
			/>
		</ListItem>
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">Position</Typography>
				}
				secondary={
					<Typography variant="body1">
						{playlist.position
							? playlist.position + 1
							: '-'}
					</Typography>
				}
			/>
		</ListItem>
	</List>
)

export default PlaylistDetails

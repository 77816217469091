import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import api from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useNavigate } from 'react-router'

const usePlaylists = ({ ...props }) => {
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const navigate = useNavigate()

	const [isLoaded, setIsLoaded] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)
	const [id, setId] = useState(props.id)
	const [playlist, setPlaylist] = useState({ id: props.id })
	const [playlists, setPlaylists] = useState([])
	const [meta, setMeta] = useState({})
	const [params, setParams] = useState({})
	const [page, setPage] = useState(1)
	const [perPage, setPerPage] = useState(20)
	const [numPages, setNumPages] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const [errors, setErrors] = useState([])
	const [sortKey, setSortKey] = useState(
		`playlists.position`
	)
	const [sortDirection, setSortDirection] = useState('asc')

	const findPlaylist = async (id) => {
		if (!id) showAlertError('No ID was provided')
		try {
			showLoading()
			setIsLoaded(false)
			setId(id)
			const res = await api.get(`/admin/playlists/${id}`)
			setPlaylist(res.data)
			setMeta(res.meta)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const findPlaylists = async (params, page = 1) => {
		try {
			showLoading()
			setParams(params)
			setPage(page)
			setIsLoaded(false)
			const res = await api.get('/admin/playlists', {
				params: {
					...params,
					page: page,
					per_page: perPage,
				},
			})
			setPlaylists(res.data)
			if (res.meta) {
				setMeta(res.meta)
				setPage(res.meta.page)
				setNumPages(res.meta.num_pages)
				setTotalCount(res.meta.total_count)
			}
			setIsEmpty(res.data.length > 0 ? false : true)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const createPlaylist = async (playlist) => {
		try {
			showLoading()
			const res = await api.post(`/admin/playlists`, {
				playlist,
			})
			if (res.data && res.data.id) {
				setPlaylist(res.data)
				setIsLoaded(true)
				setId(res.data.id)
			}
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const updatePlaylist = async (playlist) => {
		setId(playlist.id)
		try {
			showLoading()
			const res = await api.put(
				`/admin/playlists/${playlist.id}`,
				{
					playlist,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const changeIsTopPlaylist = async (playlist, value) => {
		try {
			const res = await api.put(
				`/admin/playlists/${playlist.id}`,
				{
					playlist: { is_top_playlist: !!value },
				}
			)
			reloadPlaylist()
			reloadPlaylists()
		} catch (e) {
			handleErrors(e)
		}
	}

	const updatePlaylistsPosition = useCallback(
		async (playlist, position) => {
			const res = await api.post(
				`/admin/playlists/${playlist.id}/position/${position}`
			)
			reloadPlaylist()
			reloadPlaylists()
		},
		[]
	)

	const removeAudio = async (playlistId, audioId) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/playlists/${playlistId}/remove_audio`,
				{
					audio_id: audioId,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const moveAudioUp = async (playlistId, audioId) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/playlists/${playlistId}/move_up`,
				{
					audio_id: audioId,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const moveAudioDown = async (playlistId, audioId) => {
		try {
			showLoading()
			const res = await api.post(
				`/admin/playlists/${playlistId}/move_down`,
				{
					audio_id: audioId,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			handleErrors(e)
		}
	}

	const deletePlaylist = async (playlist) => {
		try {
			showLoading()
			const res = await api.delete(
				`/admin/playlists/${playlist.id}`
			)
			setPlaylist({ data: {} })
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const paginate = (page) => findPlaylists(params, page)

	const uploadFile = async (file, attributeName) => {
		try {
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
			let formData = new FormData()
			formData.append(`playlist[${attributeName}]`, file)
			const res = await api.post(
				`/admin/playlists/${id}/upload_file`,
				formData,
				config
			)
		} catch (e) {
			handleErrors(e)
		}
	}

	const deleteFile = async (type) => {
		showLoading()
		await api.post(`/admin/playlists/${id}/delete_file`, {
			type,
		})
		hideLoading()
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setPlaylist({
			...playlist,
			[name]: value,
		})
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e?.data || e)
		console.error('usePlaylist Error:', e)
	}

	const reloadPlaylist = () => {
		if (id) return findPlaylist(id)
	}
	const reloadPlaylists = () => findPlaylists(params)

	const handleSort = (sortBy) => {
		let newSortDirection =
			sortBy == 'playlists.position'
				? 'asc'
				: sortDirection == 'asc'
				? 'desc'
				: 'asc'
		setSortDirection(newSortDirection)
		setSortKey(sortBy)
		// navigate(`?sort_key=${sortKey}&sort_direction=${sortDirection}`)
	}

	useEffect(() => {
		if (props.id) setId(props.id)
	}, [props.id])

	return {
		id,
		isLoading,
		isLoaded,
		isEmpty,
		playlist,
		playlists,
		errors,
		meta,
		findPlaylist,
		findPlaylists,
		reloadPlaylist,
		reloadPlaylists,
		changeIsTopPlaylist,
		updatePlaylistsPosition,
		createPlaylist,
		updatePlaylist,
		deletePlaylist,
		removeAudio,
		moveAudioUp,
		moveAudioDown,
		paginate,
		handleChange,
		uploadFile,
		deleteFile,
		params,
		sortKey,
		sortDirection,
		handleSort,
		page,
		numPages,
		perPage,
		totalCount,
	}
}

export default usePlaylists

import {
	Person,
	PeopleAlt,
	LibraryMusic,
	Waves,
	Star,
	Headset,
	Favorite,
	Panorama,
	Home,
	AccountBox,
} from '@material-ui/icons'

export const MENU_ITEMS = {
	loggedIn: [
		{ value: '/sessions', text: 'Classes', icon: Headset },
		{
			value: '/trainers',
			text: 'Trainers',
			icon: PeopleAlt,
		},
		{
			value: '/playlists',
			text: 'Playlists',
			icon: LibraryMusic,
		},
		{
			value: '/marketing_tile',
			text: 'Promo Tile',
			icon: AccountBox,
		},
		{
			value: '/discover_override',
			text: 'Discover Override',
			icon: Panorama,
		},
		{
			value: '/promo_playlist',
			text: 'Promo Playlist',
			icon: LibraryMusic
		},
		{ value: '/vibes', text: 'Vibes', icon: Waves },
		{ value: '/favorites', text: 'Favorites', icon: Star },
		{ value: '/rating', text: 'Ratings', icon: Favorite },
		{ value: '/users', text: 'Users', icon: Person },
	],
	loggedOut: [
		{ value: '/login', text: 'Sign In' },
		{ value: '/forgot_password', text: 'Reset password' },
	],
}

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useMarketingTile, useForm, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Subheader } from 'components'
import MarketingTileForm from './MarketingTileForm'
import MarketingTileImageEditor from './MarketingTileImageEditor'

const MarketingTileNew = ({ ...props }) => {
	const navigate = useNavigate()

	const {
		isLoading,
		isLoaded,
		marketingTile = {},
		handleChange,
		createMarketingTile,
		reloadMarketingTile,
		uploadFile,
		deleteFile,
	} = useMarketingTile()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		let newMarketingTile = await createMarketingTile(
			marketingTile
		)
		showAlertSuccess('Marketing tile has been created')
		navigate(`/marketing_tile/edit`)
	}

	return (
		<>
			<Subheader
				title="Add Promo Tile"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Box p={4}>
					<MarketingTileImageEditor
						{...{
							marketingTile,
							uploadFile,
							deleteFile,
							reloadMarketingTile,
						}}
					/>

					<MarketingTileForm
						marketingTile={marketingTile}
						handleChange={handleChange}
					/>

					<Box py={2}>
						<Button
							startIcon={<Check />}
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
						>
							Save & Continue
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default MarketingTileNew

import React, { useCallback, useEffect } from 'react'
import {
	useUsers,
	useAlerts,
	useNavigation,
	useSelected,
} from 'hooks'
import { useNavigate } from 'react-router-dom'
import {
	SortableTableHeader,
	Subheader,
	Placeholder,
	Search,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core'
import {
	Add,
	CloudDownload,
	Search as SearchIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import UserItem from 'containers/users/UserItem'
import UserToolbar from 'containers/users/UserToolbar'

const TABLE_HEADERS = [
	{
		value: 'image_url',
		label: 'Image url',
		sortable: false,
		hidden: false,
	},

	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false,
	},
	{
		value: 'email',
		label: 'Email',
		sortable: true,
		hidden: false,
	},
	{
		value: 'time_zone',
		label: 'Timezone',
		sortable: true,
		hidden: false,
	},
	{
		value: 'active_day',
		label: 'Current day',
		sortable: false,
		hidden: false,
	},
	{
		value: 'role',
		label: 'Role',
		sortable: true,
		hidden: false,
	},
]

const useStyles = makeStyles((theme) => ({
	exportButton: {
		color: 'white',
		textDecoration: 'none',
	},
}))

const UserList = ({ ...props }) => {
	const navigate = useNavigate()
	const {
		isLoading,
		isLoaded,
		isEmpty,
		users,
		findUsers,
		downloadUsersCsv,
		deleteUser,
		paginate,
		query,
		page,
		numPages,
		reloadUsers,
		sortKey,
		sortDirection,
		handleSort,
	} = useUsers({})

	const classes = useStyles()

	const { handleClick, handleEditClick, handleAddClick } =
		useNavigation({
			navigate,
			url: '/users',
		})

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected,
	} = useSelected()

	const { showAlertSuccess } = useAlerts()

	const handleDeleteClick = async (user) => {
		await deleteUser(user)
		showAlertSuccess('User has been deleted')
		reloadUsers()
	}

	useEffect(() => {
		findUsers(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection,
			},
			page
		)
	}, [query, page, sortKey, sortDirection])

	const isSelectable = false

	return (
		<>
			<Subheader
				title="Users"
				buttons={[
					<Button
						variant="contained"
						startIcon={<CloudDownload />}
						onClick={async () => await downloadUsersCsv()}
						color="primary"
					>
						Export
					</Button>,
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						User
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				{isLoaded && (
					<>
						<Paper>
							<Search
								isLoading={isLoading}
								query={query}
								handleSearch={findUsers}
							/>
							{!isEmpty ? (
								<TableContainer
									component={Paper}
									elevation={0}
								>
									<Table>
										<TableHead>
											<TableRow>
												{isSelectable && (
													<TableCell>
														<Checkbox
															checked={
																selectedIds?.length ===
																users?.length
															}
															onChange={() =>
																handleSelectAll(users)
															}
															color="primary"
														/>
													</TableCell>
												)}
												{TABLE_HEADERS.filter(
													(h) => !h.hidden
												).map((header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`users.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												))}
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{users.map((user, idx) => (
												<UserItem
													key={idx}
													user={user}
													isSelectable={isSelectable}
													selectedIds={selectedIds}
													handleSelect={handleSelect}
													handleClick={handleClick}
													handleDeleteClick={
														handleDeleteClick
													}
													handleEditClick={handleEditClick}
												/>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Placeholder
									icon={<SearchIcon />}
									title="No users"
									subtitle="Please try another search"
								/>
							)}
						</Paper>
						{!isEmpty && (
							<Box my={1}>
								<Pagination
									onChange={(ev, page) => paginate(page)}
									color="primary"
									size="small"
									shape="rounded"
									page={page}
									count={numPages}
								/>
							</Box>
						)}
					</>
				)}
				<UserToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	)
}

export default UserList

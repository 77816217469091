import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useAudios, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Subheader } from 'components'
import AudioDetails from 'containers/audios/AudioDetails'
import PlaylistListItem from 'containers/playlists/PlaylistListItem'

const AudioShow = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const {
		audioId,
		isLoading,
		isLoaded,
		audio,
		findAudio,
		changePublished,
		changeFeatured,
	} = useAudios({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleEditClick = () =>
		navigate(`/sessions/${id}/edit`)

	const togglePublished = (ev) => changePublished(audio, !audio.published)
	const toggleFeatured = (ev) => changeFeatured(audio, !audio.featured)

	useEffect(() => {
		if (id) findAudio(id)
	}, [])

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `${audio.name}`}
				buttons={[
					<Button
						variant="contained"
						onClick={handleEditClick}
						color="primary"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<AudioDetails
							audio={audio}
							togglePublished={togglePublished}
							toggleFeatured={toggleFeatured}
						/>
					</Box>
				</Paper>

				{audio?.playlists?.length > 0 ? (
					<>
						<Box my={4} />
						<Paper>
							<Box p={4}>
								{audio.playlists.map((playlist, i) => (
									<PlaylistListItem
										key={i}
										playlist={playlist}
									/>
								))}
							</Box>
						</Paper>
					</>
				) : null}
			</Container>
		</>
	)
}

export default AudioShow

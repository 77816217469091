import React, { memo, useState } from 'react'

import { AudiosContext } from './AudiosContext'

const AudiosProvider = ({ children }) => {
	const [currentPage, setCurrentPage] = useState([])

	const value = {
		currentPage,
		setCurrentPage,
	}

	return (
		<AudiosContext.Provider {...{ value }}>
			{children}
		</AudiosContext.Provider>
	)
}

export default memo(AudiosProvider)

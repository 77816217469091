import React from 'react'
import { Avatar } from '@material-ui/core'
import { InsertPhoto } from '@material-ui/icons'
import styled from 'styled-components'

const SquareImage = ({
	src,
	variant = 'rounded',
	size = 40,
}) => (
	<StyledAvatar src={src} size={size} variant={variant}>
		<InsertPhoto />
	</StyledAvatar>
)

export default SquareImage

const StyledAvatar = styled(Avatar)`
	height: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
`

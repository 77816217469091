import React from 'react'
import {
	Checkbox,
	FormControlLabel,
	Grid,
} from '@material-ui/core'
import {
	Autosuggest,
	AsyncAutosuggest,
	SwitchInput,
	TextInput,
} from 'components'

import { CLASS_TYPE_OPTION_MAP } from './class_type'
import { AUDIO_IMAGE_KEYS } from 'lib/constants'
import { MEMBERSHIP_TYPE_OPTION_MAP } from './membership_type'

const AudioForm = ({ audio, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={audio.name ?? ''}
				label="Name"
				name="name"
				placeholder="Name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={audio.media_url ?? ''}
				label="Media Url"
				name="media_url"
				placeholder="Media Url"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				multiline
				rows={3}
				value={audio.description ?? ''}
				label="Description"
				name="description"
				placeholder="Description"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Autosuggest
				value={audio.class_type}
				defaultValue={CLASS_TYPE_OPTION_MAP.on_demand}
				options={Object.values(CLASS_TYPE_OPTION_MAP)}
				label="Class Type"
				name="class_type"
				placeholder="Class Type"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Autosuggest
				value={audio.membership_type}
				defaultValue={MEMBERSHIP_TYPE_OPTION_MAP.on_demand}
				options={Object.values(MEMBERSHIP_TYPE_OPTION_MAP)}
				label="Membership Type"
				name="membership_type"
				placeholder="Membership Type"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<AsyncAutosuggest
				value={audio.vibe_id}
				label="Vibe"
				name="vibe_id"
				placeholder="Vibe"
				remoteUrl="/admin/vibes"
				displayField="name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Autosuggest
				value={audio.image_key}
				defaultValue={AUDIO_IMAGE_KEYS[0].value}
				options={AUDIO_IMAGE_KEYS}
				label="Vibe Image"
				name="image_key"
				placeholder="Image"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<AsyncAutosuggest
				value={audio.trainer_id}
				label="Trainer"
				name="trainer_id"
				placeholder="Trainer"
				remoteUrl="/admin/trainers"
				displayField="name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				multiline
				rows={3}
				value={audio.quote ?? ''}
				label="Quote"
				name="quote"
				placeholder="Quote"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<SwitchInput
				value={audio.published === true ? true : false}
				label="Published"
				name="published"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<SwitchInput
				value={audio.featured === true ? true : false}
				label="Featured"
				name="featured"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
)

export default AudioForm

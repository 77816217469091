import React from 'react'
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'
import mobile from 'is-mobile'

const Modal = ({
	open,
	handleClose,
	icon,
	title,
	subtitle,
	actions,
	component,
	maxWidth,
	fullScreen,
}) => (
	<StyledDialog
		fullWidth
		maxWidth={maxWidth || 'sm'}
		fullScreen={
			mobile() || fullScreen === true ? true : false
		}
		open={open}
		onClose={handleClose}
	>
		<StyledDialogTitle onClose={handleClose}>
			<Box display="flex" justifyContent="space-between">
				<Box display="flex" flexDirection="row">
					<Title variant="h4">{title}</Title>
				</Box>
				<Box>
					<CloseButton onClick={handleClose}>
						<CloseIcon />
					</CloseButton>
				</Box>
			</Box>
		</StyledDialogTitle>
		<DialogContent>
			{subtitle && (
				<Typography variant="body1">{subtitle}</Typography>
			)}
			{component}
		</DialogContent>
		{actions && <DialogActions>{actions}</DialogActions>}
	</StyledDialog>
)

export default Modal

const StyledDialog = styled(Dialog)`
	z-index: 1500;
`

const StyledDialogTitle = styled(DialogTitle)`
	margin-bottom: 10px;
	height: 64px;
`

const Title = styled(Typography)``

const CloseIcon = styled(Close)``

const CloseButton = styled(IconButton)`
	margin: 0;
	padding: 0;
`

import React from 'react'

const AspectRatioImage = ({
	defaultWidth,
	defaultHeight,
	defaultAspectRatio,
}) => {
	let DEFAULT_HEIGHT, DEFAULT_WIDTH, DEFAULT_ASPECT_RATIO

	// first, set the DEFAULT_WIDTH and/or DEFAULT_HEIGHT if they have been provided

	if (defaultWidth != null) {
		DEFAULT_WIDTH = defaultWidth
	}

	if (defaultHeight != null) {
		DEFAULT_HEIGHT = defaultHeight
	}

	// second, set the DEFAULT_ASPECT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT if they have been provided

	if (DEFAULT_WIDTH != null && DEFAULT_HEIGHT != null) {
		if (defaultAspectRatio != null) {
			const error = new Error(
				`(AspectRatioImage) do not set \`defaultAspectRatio\` when also setting \`defaultWidth\` and \`defaultHeight\`. \`defaultAspectRatio\` is inferred to be \`defaultWidth / defaultHeight\``
			)
			throw error
		}

		DEFAULT_ASPECT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT
	}

	// third, set the DEFAULT_ASPECT_RATIO = defaultAspectRatio if it has been provided

	if (defaultAspectRatio != null) {
		DEFAULT_ASPECT_RATIO = defaultAspectRatio
	}

	// fourth, set the DEFAULT_WIDTH or DEFAULT_HEIGHT based on the other two provided values

	if (DEFAULT_WIDTH == null) {
		if (
			DEFAULT_HEIGHT == null ||
			DEFAULT_ASPECT_RATIO == null
		) {
			const error = new Error(
				`(AspectRatioImage) must set two out of three of: \`defaultWidth\`, \`defaultHeight\`, or \`defaultAspectRatio\``
			)
			throw error
		}

		DEFAULT_WIDTH = Math.floor(
			DEFAULT_HEIGHT * DEFAULT_ASPECT_RATIO
		)
	}

	if (DEFAULT_HEIGHT == null) {
		if (
			DEFAULT_WIDTH == null ||
			DEFAULT_ASPECT_RATIO == null
		) {
			const error = new Error(
				`(AspectRatioImage) must set two out of three of: \`defaultWidth\`, \`defaultHeight\`, or \`defaultAspectRatio\``
			)
			throw error
		}

		DEFAULT_HEIGHT = Math.floor(
			DEFAULT_WIDTH * (1 / DEFAULT_ASPECT_RATIO)
		)
	}

	// fifth, return the generated element based on the default constants

	return ({ src, width = null, height = null }) => {
		if (width == null && height != null) {
			width = Math.floor(height * DEFAULT_ASPECT_RATIO)
		}

		if (width != null && height == null) {
			height = Math.floor(
				width * (1 / DEFAULT_ASPECT_RATIO)
			)
		}

		if (width == null && height == null) {
			width = DEFAULT_WIDTH
			height = DEFAULT_HEIGHT
		}

		return (
			<img
				src={src}
				width={width}
				height={height}
				style={{ objectFit: 'cover' }}
			/>
		)
	}
}

export default AspectRatioImage

import React from 'react'
import { useNavigate } from 'react-router'
import {
	useAlerts,
	usePromoPlaylist,
} from 'hooks'
import {
	Box,
	Paper,
	Button,
	Container,
} from '@material-ui/core'
import { Subheader } from 'components'
import { Check } from '@material-ui/icons'
import PromoPlaylistForm from './PromoPlaylistForm'

const PromoPlaylistEdit = () => {
	const navigate = useNavigate()

	const {
		promoPlaylist,
		handleChange,
		updatePromoPlaylist
	} = usePromoPlaylist()

	const { showAlertSuccess } = useAlerts()

	const handleSaveClick = async () => {
		await updatePromoPlaylist(promoPlaylist)
		showAlertSuccess('Promo Playlist has been updated')
		navigate(`/promo_playlist`)
	}

	return (
		<>
			<Subheader title="Edit Promo Playlist" />
			<Container>
				<Paper>
					<Box p={4}>
						<PromoPlaylistForm
							promoPlaylist={promoPlaylist}
							handleChange={handleChange}
						/>

						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default PromoPlaylistEdit

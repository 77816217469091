import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Typography } from '@material-ui/core'
import { syntheticEvent } from 'lib/utils'
import { selectStyles } from 'components/styles'
import styled from 'styled-components'

const StyledContainer = styled('div')`
	${(props) => props.height && `height: ${props.height}px;`}
`

const Autosuggest = ({
	value,
	options,
	label,
	name,
	placeholder,
	multiselect,
	position,
	handleChange,
	isSearchable = true,
	...props
}) => {
	const inputProps = {
		autoComplete: 'none',
		autoCorrect: 'off',
		spellCheck: 'off',
	}

	const [option, setOption] = useState()
	const [height, setHeight] = useState()

	useEffect(() => {
		if (value) setOptionFromValue(value)
	}, [value])

	const setOptionFromValue = (value) => {
		let opt = options.find((o) => o.value == value)
		setOption(opt)
	}

	const handleExpand = () =>
		position == 'static' && setHeight(340)
	const handleShrink = () => setHeight()

	const handleInputChange = (selectedOption) => {
		setOption(selectedOption)
		let _value
		if (multiselect) {
			_value = selectedOption.map((o) => o && o.value)
		} else {
			_value = selectedOption && selectedOption.value
		}
		let ev = syntheticEvent(_value, name)
		handleChange(ev)
	}

	return (
		<StyledContainer height={height}>
			<Typography variant="body2">{label}</Typography>
			<Select
				isClearable
				autocomplete="none"
				isSearchable={isSearchable}
				inputProps={{ autoComplete: 'off' }}
				placeholder={placeholder}
				isMulti={multiselect ? true : false}
				value={option}
				styles={selectStyles}
				onChange={handleInputChange}
				onMenuClose={handleShrink}
				onMenuOpen={handleExpand}
				options={options}
			/>
		</StyledContainer>
	)
}

export default Autosuggest

import React, { useContext, useState } from 'react'
import { useAlerts, useAuth } from 'hooks'
import { AppContext } from 'context'
import {
	Link as RouterLink,
	useNavigate,
} from 'react-router-dom'
import {
	Box,
	Button,
	Container,
	Grid,
	Link,
	Typography,
} from '@material-ui/core'
import { Column, TextInput } from 'components'
import { ChevronRight } from '@material-ui/icons'

const ResetPassword = ({ ...props }) => {
	const [pin, setPin] = useState()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const { authenticated, currentUser } =
		useContext(AppContext)

	const { verifyPin } = useAuth()

	const navigate = useNavigate()

	const handleSubmit = async () => {
		try {
			let verified = await verifyPin(pin)
			if (verified) {
				showAlertSuccess('PIN is verified')
				navigate(`/reset_password/${pin}`)
			} else {
				showAlertError('PIN is not valid')
			}
		} catch (e) {
			console.log(e)
			showAlertError("A user with that pin doesn't exist")
		}
	}

	const handleChange = ({ target }) => setPin(target.value)

	return (
		<Container maxWidth="sm">
			<Box my={10}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextInput
							fullWidth
							required={true}
							value={pin}
							name="pin"
							label="6-Digit Pin"
							placeholder="Enter your pin"
							handleChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							size="large"
							variant="contained"
							color="primary"
							endIcon={<ChevronRight />}
							onClick={handleSubmit}
						>
							Verify and continue
						</Button>
					</Grid>
				</Grid>
				<Box my={4} />
				<Typography variant="body2">
					<Link component={RouterLink} to="/login">
						Sign in
					</Link>
				</Typography>
			</Box>
		</Container>
	)
}

export default ResetPassword

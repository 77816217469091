import React from 'react'
import { Typography } from '@material-ui/core'
import { SquareImage } from 'components'

const DiscoverOverrideImage = ({
	discoverOverride = {},
}) => {
	return (
		<center>
			<Typography variant="body2">Image (Sq.)</Typography>
			<SquareImage
				src={discoverOverride?.image_url}
				variant="rounded"
				size={420}
			/>
		</center>
	)
}

export default DiscoverOverrideImage

import { useCallback, useEffect, useState } from 'react'
import api from 'api'

const useDailyAffirmations = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [dailyAffimationAudios, setDailyAffirmationAudios] =
		useState([])

	const reload = useCallback(async () => {
		setIsLoading(true)
		const res = await api.get('/audios/daily_affirmations')
		setDailyAffirmationAudios(res.data ?? [])
		setIsLoading(false)
	}, [])

	const addDailyAffirmation = useCallback(
		async (audioId) => {
			const res = await api.put(
				`/admin/audios/${audioId}`,
				{
					audio: { is_selected_daily_affirmation: true },
				}
			)
			reload()
		},
		[]
	)

	const addAllDailyAffirmations = useCallback(
		async (audioIds) => {
			const res = await api.post(
				`/admin/audios/set_is_selected_daily_affirmation`,
				{ audio_ids: audioIds }
			)
			reload()
		},
		[]
	)

	const removeDailyAffirmation = useCallback(
		async (audioId) => {
			const res = await api.put(
				`/admin/audios/${audioId}`,
				{
					audio: { is_selected_daily_affirmation: false },
				}
			)
			reload()
		},
		[]
	)

	const updateDailyAffirmationsPosition = async (
		audioId,
		position
	) => {
		const res = await api.post(
			`/admin/audios/${audioId}/daily_affirmations_position/${position}`
		)
		reload()
	}

	useEffect(() => {
		reload()
	}, [])

	return {
		isLoading,
		dailyAffimationAudios,
		addDailyAffirmation,
		addAllDailyAffirmations,
		removeDailyAffirmation,
		updateDailyAffirmationsPosition,
		reload,
	}
}

export default useDailyAffirmations

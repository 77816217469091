import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import {
	Box,
	Button,
	LinearProgress,
	Typography,
} from '@material-ui/core'
import { SquareImage } from 'components'
import styled from 'styled-components'

const IMAGE_WIDTH = 140

const styles = {
	defaultImage: ({ height = 160, width = 160 }) => ({
		padding: '3px',
		backgroundColor: '#fff',
		border: '1px solid #ccc',
		height,
		width,
		margin: '0 0 10px 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	editButton: {
		margin: '0 0 0 0',
	},
}

const ImageUploader = ({
	circle = true,
	override = false,
	handleUpload,
	height,
	width,
	size = IMAGE_WIDTH,
	imageUrl,
	demoImage,
	handleDelete,
	...props
}) => {
	if (width == null && height == null) {
		width = size
		height = size
	}

	const [isEditing, setIsEditing] = useState(false)
	const [file, setFile] = useState()
	const [files, setFiles] = useState([])
	const [isUploading, setIsUploading] = useState(false)
	const [isUploaded, setIsUploaded] = useState(false)
	const [isOverrideClicked, setIsOverrideClicked] =
		useState(false)
	const [dropzoneActive, setDropzoneActive] =
		useState(false)

	const handleEditClick = () => setIsEditing(!isEditing)

	const onDrop = async (files) => {
		setIsUploading(true)
		setIsUploaded(false)
		await handleUpload(files[0])
		setIsEditing(false)
		setIsUploading(false)
		setIsUploaded(true)
		setFiles([])
		setFile(null)
	}

	const onDragEnter = (files) => {
		setFiles(files)
		setDropzoneActive(true)
	}

	const onDragLeave = (files) => {
		setFiles(files)
		setDropzoneActive(false)
	}

	const DemoImage =
		demoImage ??
		(() => <SquareImage size={size} src={imageUrl} />)

	return (
		<div>
			{imageUrl && !isEditing && <DemoImage />}

			{(isEditing || !imageUrl || isOverrideClicked) && (
				<StyledDropzone
					height={height}
					width={width}
					disableClick={false}
					accept={{
						'image/*': [
							'.gif',
							'.jpeg',
							'.jpg',
							'.png',
							'.webp',
						],
					}}
					onDrop={onDrop}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					multiple={false}
					activeStyle={styles.dropzoneActive}
				>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div
								{...getRootProps()}
								style={styles.defaultImage({
									height: isOverrideClicked ? 40 : height,
									width,
								})}
							>
								<input {...getInputProps()} />
								<center>
									{!isUploaded &&
										!isUploading &&
										!dropzoneActive && (
											<Typography
												variant="overline"
												color="primary"
											>
												Upload image
											</Typography>
										)}

									{isUploaded && (
										<Typography
											variant="overline"
											color="primary"
										>
											Upload complete
										</Typography>
									)}

									{!isUploaded &&
										!isUploading &&
										dropzoneActive && (
											<Typography
												variant="overline"
												color="primary"
											>
												Drop image
											</Typography>
										)}

									{!isUploaded && isUploading && (
										<Typography
											variant="overline"
											color="primary"
										>
											Uploading ...
										</Typography>
									)}

									{isUploading && <LinearProgress />}
								</center>
							</div>
						</section>
					)}
				</StyledDropzone>
			)}

			{imageUrl && handleDelete && (
				<Box>
					<ActionButton
						color="secondary"
						size="small"
						style={styles.editButton}
						onClick={handleDelete}
					>
						Remove
					</ActionButton>
					{override && (
						<ActionButton
							color="secondary"
							size="small"
							style={styles.editButton}
							onClick={() => {
								setIsOverrideClicked((value) => !value)
							}}
						>
							Override
						</ActionButton>
					)}
				</Box>
			)}
		</div>
	)
}

export default ImageUploader

const StyledImage = styled('img')`
	padding: 2px;
	background-color: #fff;
	margin: 0 0 10px 0;
	${(props) =>
		props.circle === 'true' && `border-radius: 100px;`}
`

const StyledDropzone = styled(Dropzone)`
	margin: 0;
	border-radius: 4px;
	padding: ${(props) => `${(props.height - 38) / 2}px`} 20px;
	border: 1px dotted #333;
	color: #555;
	${(props) =>
		`width: ${props.width}px; height: ${props.height}px;`}
	background-color: #fff;
	text-align: center;
	margin: 0 0 10px 0;
`

const ActionButton = styled(Button)`
	font-size: 10px;
	color: #666666;
`

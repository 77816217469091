import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { usePlaylists, useAlerts } from 'hooks'
import {
	Box,
	List,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Subheader } from 'components'
import MarketingTileDetails from 'containers/marketingTile/MarketingTileDetails'
import { sortBy } from 'lodash'
import { useMarketingTile } from 'hooks'

const MarketingTileShow = ({ ...props }) => {
	const navigate = useNavigate()

	const {
		isLoading,
		isLoaded,
		marketingTile,
		removeMarketingTile,
		changePublished,
	} = useMarketingTile()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleCreateClick = async () =>
		navigate(`/marketing_tile/new`)

	const handleEditClick = () =>
		navigate(`/marketing_tile/edit`)

	const handleRemoveClick = async (marketingTile) => {
		await removeMarketingTile()
	}

	const togglePublished = (ev) =>
		changePublished(!marketingTile.published)

	if (!isLoading && !marketingTile) {
		return (
			<>
				<Subheader
					title="Create Promo Tile"
					buttons={[
						<Button
							onClick={handleCreateClick}
							color="primary"
							variant="contained"
						>
							Create
						</Button>,
					]}
				/>
			</>
		)
	}

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : 'Promo Tile'}
				buttons={[
					<Button
						onClick={handleEditClick}
						color="primary"
						variant="contained"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<MarketingTileDetails
							marketingTile={marketingTile}
							togglePublished={togglePublished}
						/>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default MarketingTileShow

import React, { useState } from 'react'
import { Box, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import FilterRow from './FilterRow'
import { chunks } from '../lib/utils'

const useStyles = makeStyles((theme) => ({
	filterRow: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		marginTop: 10,
	},
	expandButton: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
	},
}))

const Filter = ({
	filters,
	selectedFilters,
	expanded,
	onFilterChange,
	onExpandStateChange,
	numColumns = 2,
	numCollapsedRows = 2,
}) => {
	const classes = useStyles()

	const filterChunks = [...chunks(filters, numColumns)]
	const slices = filterChunks.slice(
		0,
		expanded ? filterChunks.length : numCollapsedRows
	)

	return (
		<Box p={1} px={2}>
			<Typography variant="overline" gutterBottom>
				{'Filters'}
			</Typography>

			{slices.map((row, idx) => (
				<FilterRow
					key={idx}
					filters={row}
					selectedFilters={selectedFilters}
					onFilterChange={onFilterChange}
				/>
			))}

			{filterChunks.length > numCollapsedRows && (
				<div className={classes.expandButton}>
					<IconButton
						onClick={() => {
							onExpandStateChange(! expanded)
						}}
					>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
				</div>
			)}
		</Box>
	)
}

export default Filter

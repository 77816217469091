import React from 'react'
import { useAudios, useMenu } from 'hooks'
import {
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox,
	Box,
	Grid,
} from '@material-ui/core'
import {
	CarouselImage,
	FullScreenImage,
	SquareImage,
	SwitchInput,
} from 'components'
import {
	MoreHoriz,
	Person,
	PlayCircleFilled,
	Stop,
	MusicOff,
} from '@material-ui/icons'
import { resize } from 'lib/cloudinary'

import { CLASS_TYPES } from './class_type'
import { MEMBERSHIP_TYPES } from './membership_type'

const AudioItem = ({
	audio: originalAudio,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	changeIsMuneTopPick,
	...props
}) => {
	const { audio: recentAudio, changePublished } = useAudios(
		{ id: originalAudio?.id }
	)
	const audio = Object.assign(
		{},
		originalAudio,
		recentAudio
	)

	const { open, anchorEl, handleToggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem onClick={(e) => handleEditClick(audio, e)}>
				Edit
			</MenuItem>
			<MenuItem
				onClick={(e) => handleDeleteClick(audio, e)}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	const isSelected = selectedIds.includes(audio.id)

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(audio)}
						name="audio"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Box p={1}>
								<FullScreenImage
									src={resize(audio.fullscreen_image_url, {
										height: 20,
									})}
									height={40}
								/>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box p={1}>
								<SquareImage
									src={resize(audio.thumbnail_image_url, {
										height: 20,
									})}
									size={40}
								/>
							</Box>
						</Grid>
					</Grid>
				</Button>
			</TableCell>
			<TableCell>
				<IconButton onClick={() => handleClick(audio)}>
					{audio.media_url ? (
						<PlayCircleFilled />
					) : (
						<MusicOff />
					)}
				</IconButton>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					{audio.name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					{audio.class_type
						? CLASS_TYPES[audio.class_type]
						: '-'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					{audio.membership_type
						? MEMBERSHIP_TYPES[audio.membership_type]
						: '-'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					{audio.vibe && (
						<Box
							height={16}
							width={16}
							marginRight={1}
							style={{
								backgroundColor: audio.vibe?.color,
								border: '1px solid #ddd',
							}}
						/>
					)}
					{audio.vibe?.name ?? '-'}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					{audio?.trainer?.name}
				</Button>
			</TableCell>
			<TableCell>
				<SwitchInput
					name="published"
					value={audio.published}
					handleChange={() =>
						changePublished(audio, !audio.published)
					}
				/>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(audio)}>
					{audio?.featured === true ? 'Yes' : '-'}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => handleToggleMenu(e)}>
					<MoreHoriz />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default AudioItem

import React, { useEffect } from 'react'
import { useAuth } from 'hooks'
import {
	createTheme,
	MuiThemeProvider,
} from '@material-ui/core/styles'
import { Alert, Loader, Header, Layout } from 'components'
import { CssBaseline } from '@material-ui/core'
import { muiTheme } from 'theme'
import Routes from 'routes'

const theme = createTheme(muiTheme)

const App = (props) => {
	const { authenticateFromToken } = useAuth()

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) authenticateFromToken(token)
	}, [])

	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<Alert />
			<Header />
			<Layout>
				<Routes {...props} />
			</Layout>
			<Loader />
		</MuiThemeProvider>
	)
}

export default App

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { usePlaylists, useAlerts } from 'hooks'
import {
	Box,
	List,
	Paper,
	Button,
	Typography,
	Container,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Subheader } from 'components'
import PlaylistDetails from 'containers/playlists/PlaylistDetails'
import PlaylistAudioItem from 'containers/audios/PlaylistAudioItem'
import { sortBy } from 'lodash'

const PlaylistShow = ({ ...props }) => {
	const navigate = useNavigate()
	const { id } = useParams()

	const {
		playlistId,
		isLoading,
		isLoaded,
		playlist,
		findPlaylist,
		updatePlaylistsPosition,
		changeIsTopPlaylist,
		removeAudio,
		moveAudioUp,
		moveAudioDown,
	} = usePlaylists({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleEditClick = () =>
		navigate(`/playlists/${id}/edit`)

	const handleRemoveClick = async (audio) => {
		await removeAudio(id, audio?.id)
		findPlaylist(id)
	}

	const handleMoveUp = async (audio) => {
		await moveAudioUp(id, audio?.id)
		findPlaylist(id)
	}

	const handleMoveDown = async (audio) => {
		await moveAudioDown(id, audio?.id)
		findPlaylist(id)
	}

	useEffect(() => {
		if (id) findPlaylist(id)
	}, [])

	return (
		<>
			<Subheader
				title={
					isLoading ? 'Loading...' : `${playlist.name}`
				}
				buttons={[
					<Button
						onClick={handleEditClick}
						color="primary"
						variant="contained"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<PlaylistDetails
							playlist={playlist}
							changeIsTopPlaylist={changeIsTopPlaylist}
						/>
					</Box>
				</Paper>
				<Box my={4} />
				{playlist.playlist_audios &&
				playlist.playlist_audios.length > 0 ? (
					<Paper>
						<List>
							{sortBy(
								playlist.playlist_audios,
								'position',
								'asc'
							).map((playlistAudio, idx) => (
								<PlaylistAudioItem
									key={playlistAudio.id}
									position={idx + 1}
									audio={playlistAudio.audio}
									handleRemoveClick={handleRemoveClick}
									handleMoveUp={handleMoveUp}
									handleMoveDown={handleMoveDown}
								/>
							))}
						</List>
					</Paper>
				) : null}
			</Container>
		</>
	)
}

export default PlaylistShow

import React from 'react'
import {
	Grid,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@material-ui/core'
import { Autosuggest, TextInput } from 'components'

const VibeForm = ({ vibe, isEditing, handleChange }) => {
	const handleSwitch = (ev, value) => {
		let event = { target: { name: 'featured', value } }
		handleChange(event)
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={vibe.name}
					label="Name"
					name="name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					value={vibe.color}
					label="Color"
					name="color"
					placeholder="#FFFFFF"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default VibeForm

import React from 'react'
import { Typography } from '@material-ui/core'
import { ImageUploader, SquareImage } from 'components'

const MarketingTileImageEditor = ({
	marketingTile = {},
	uploadFile,
	deleteFile,
	reloadMarketingTile,
}) => {
	const handleUpload = async (file) => {
		await uploadFile(file, 'image_url')
		reloadMarketingTile()
	}

	const handleDelete = async (file) => {
		await deleteFile('image_url')
		reloadMarketingTile()
	}

	return (
		<center>
			<Typography variant="body2">Image (Sq.)</Typography>
			<ImageUploader
				width={420}
				height={420}
				imageUrl={marketingTile?.image_url}
				demoImage={() => (
					<SquareImage
						src={marketingTile?.image_url}
						variant="rounded"
						size={420}
					/>
				)}
				handleUpload={handleUpload}
				handleDelete={handleDelete}
			/>
		</center>
	)
}

export default MarketingTileImageEditor

import palette from './palette'

export default {
	h1: {
		fontFamily: 'Poppins',
		fontWeight: 600,
		color: '#ffffff',
		fontSize: '72px',
		lineHeight: '1.5em',
		'@media (max-width:600px)': {
			fontSize: '54px',
		},
	},
	h2: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontWeight: 600,
		fontSize: '42px',
		'@media (max-width:600px)': {
			fontSize: '36px',
		},
		lineHeight: '1.5em',
	},
	h3: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontWeight: 600,
		textIndent: 0,
		fontSize: '32px',
		lineHeight: '1.5em',
	},
	h4: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontWeight: 600,
		fontSize: '24px',
		lineHeight: '1.5em',
	},
	h5: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '2.0em',
	},
	h6: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '20px',
	},
	subtitle1: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontSize: '16px',
		lineHeight: '2.0em',
	},
	subtitle2: {
		fontFamily: 'Poppins',
		color: palette.text.secondary,
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '2.0em',
	},
	body1: {
		fontFamily: 'Poppins',
		fontWeight: 400,
		color: palette.text.primary,
		fontSize: '15px',
		lineHeight: '1.5em',
	},
	body2: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontSize: '12px',
		fontWeight: 400,
		lineHeight: '1.5em',
		textDecoration: 'none',
	},
	button: {
		fontFamily: 'Poppins',
		color: palette.text.primary,
		fontSize: '15px',
		textTransform: 'none',
	},
	caption: {
		fontFamily: 'Poppins',
		color: palette.text.secondary,
		fontSize: '13px',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '15px',
		textTransform: 'uppercase',
	},
	overline: {
		fontFamily: 'Poppins',
		color: palette.text.secondary,
		fontSize: '11px',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '13px',
		textTransform: 'uppercase',
	},
}

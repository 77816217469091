import React, { useEffect } from 'react'
import {
	useTrainers,
	useAlerts,
	useNavigation,
	useSelected,
} from 'hooks'
import { useNavigate } from 'react-router-dom'
import {
	Layout,
	SortableTableHeader,
	Column,
	Subheader,
	Page,
	Placeholder,
	Search,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	Tooltip,
	Toolbar,
} from '@material-ui/core'
import {
	Add,
	Search as SearchIcon,
} from '@material-ui/icons'
import TrainerItem from 'containers/trainers/TrainerItem'
import TrainerToolbar from 'containers/trainers/TrainerToolbar'

const TABLE_HEADERS = [
	{
		value: 'avatar_image_url',
		label: 'Avatar image url',
		sortable: false,
		hidden: false,
	},
	{
		value: 'fullscreen_image_url',
		label: 'Fullscreen image url',
		sortable: false,
		hidden: false,
	},
	{
		value: 'media_url',
		label: 'Trainer audio',
		sortable: false,
		hidden: false,
	},
	{
		value: 'featured',
		label: 'Featured',
		sortable: false,
		hidden: false,
	},
	{
		value: 'name',
		label: 'Name',
		sortable: true,
		hidden: false,
	},
]

const TrainerList = ({ ...props }) => {
	const navigate = useNavigate()
	const {
		isLoading,
		isLoaded,
		isEmpty,
		trainers,
		findTrainers,
		deleteTrainer,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadTrainers,
		sortKey,
		sortDirection,
		handleSort,
	} = useTrainers({})

	const { handleClick, handleEditClick, handleAddClick } =
		useNavigation({
			navigate,
			url: '/trainers',
		})

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected,
	} = useSelected()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleDeleteClick = async (trainer) => {
		await deleteTrainer(trainer)
		showAlertSuccess('Trainer has been deleted')
		reloadTrainers()
	}

	useEffect(() => {
		findTrainers(
			{
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection,
			},
			page
		)
	}, [query, page, sortKey, sortDirection])

	const isSelectable = false

	return (
		<>
			<Subheader
				title="Trainers"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Trainer
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				{isLoaded && (
					<>
						<Paper>
							<Search
								isLoading={isLoading}
								query={query}
								handleSearch={findTrainers}
							/>
							{!isEmpty ? (
								<TableContainer
									component={Paper}
									elevation={0}
								>
									<Table>
										<TableHead>
											<TableRow>
												{isSelectable && (
													<TableCell>
														<Checkbox
															checked={
																selectedIds?.length ===
																trainers?.length
															}
															onChange={() =>
																handleSelectAll(trainers)
															}
															color="primary"
														/>
													</TableCell>
												)}
												{TABLE_HEADERS.filter(
													(h) => !h.hidden
												).map((header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`trainers.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												))}
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{trainers.map((trainer, idx) => (
												<TrainerItem
													key={idx}
													trainer={trainer}
													isSelectable={isSelectable}
													selectedIds={selectedIds}
													handleSelect={handleSelect}
													handleClick={handleClick}
													handleDeleteClick={
														handleDeleteClick
													}
													handleEditClick={handleEditClick}
												/>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Placeholder
									icon={<SearchIcon />}
									title="No trainers"
									subtitle="Please try another search"
								/>
							)}
						</Paper>
						{!isEmpty && (
							<Box my={1}>
								<Pagination
									onChange={(ev, page) => paginate(page)}
									color="primary"
									size="small"
									shape="rounded"
									page={page}
									count={numPages}
								/>
							</Box>
						)}
					</>
				)}
				<TrainerToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	)
}

export default TrainerList

import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Box,
	Button,
	List,
	Paper,
	Container,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { useNavigation } from 'hooks'
import useMuneTopPicks from 'hooks/useMuneTopPicks'
import { Subheader } from 'components'
import PlaylistAudioItem from 'containers/audios/PlaylistAudioItem'

const MuneTopPicksShow = ({ ...props }) => {
	const navigate = useNavigate()

	const { handleAddClick } = useNavigation({
		navigate,
		url: '/curated_lists/mune_top_picks',
	})

	const {
		isLoading,
		muneTopPicksAudios = [],
		updateMuneTopPicksPosition,
		removeMuneTopPick,
	} = useMuneTopPicks()

	return (
		<>
			<Subheader
				title="MUNE Top Picks"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Audios
					</Button>,
				]}
			/>
			<Container>
				<Box my={4} />
				{muneTopPicksAudios &&
				muneTopPicksAudios.length > 0 ? (
					<Paper>
						<List>
							{muneTopPicksAudios.map((audio, idx) => (
								<PlaylistAudioItem
									key={idx}
									audio={audio}
									position={
										audio.mune_top_picks_position + 1
									}
									handleMoveUp={async () =>
										await updateMuneTopPicksPosition(
											audio.id,
											audio.mune_top_picks_position - 1
										)
									}
									handleMoveDown={async () =>
										await updateMuneTopPicksPosition(
											audio.id,
											audio.mune_top_picks_position + 1
										)
									}
									handleRemoveClick={async () =>
										await removeMuneTopPick(audio.id)
									}
								/>
							))}
						</List>
					</Paper>
				) : null}
			</Container>
		</>
	)
}

export default MuneTopPicksShow

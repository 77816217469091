import React, { useContext, useEffect } from 'react'
import { useAlerts, useAuth } from 'hooks'
import { AppContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { Box, Container } from '@material-ui/core'
import LoginForm from 'containers/auth/LoginForm'

const Login = ({ ...props }) => {
	const navigate = useNavigate()

	const { authenticated, currentUser } =
		useContext(AppContext)

	const { user, handleChange, fetchMe, loginUser } =
		useAuth()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const handleSubmit = async () => {
		await loginUser(user)
	}

	const handleKeyPress = (event) => {
		if (event.key == 'Enter') handleSubmit()
	}

	return (
		<Container maxWidth="sm">
			<Box my={10}>
				<LoginForm
					user={user}
					handleChange={handleChange}
					handleKeyPress={handleKeyPress}
					handleSubmit={handleSubmit}
				/>
			</Box>
		</Container>
	)
}

export default Login

import React, { useEffect } from 'react'
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core'
import ReactAudioPlayer from 'react-audio-player'
import { FullScreenImage, SquareImage, SwitchInput } from 'components'
import { useVibes } from 'hooks'

const TrainerDetails = ({ trainer, toggleFeatured }) => {
	const { vibes = [], vibesById, findVibes } = useVibes()

	useEffect(() => {
		findVibes()
	}, [])

	const imgUrlByVibeId =
		trainer?.avatar_image_url_by_vibe_id ?? {}

	return (
		<Grid container spacing={1}>
			<Grid container>
				<Grid item xs={12} sm={4}>
					<Box p={4}>
						<center>
							<Typography variant="body2">
								Fullscreen Image
							</Typography>
							<FullScreenImage
								src={trainer?.fullscreen_image_url}
								height={320}
							/>
						</center>
					</Box>
					<Box p={2}>
						<center>
							<Typography variant="body2">
								Avatar Image
							</Typography>
							<SquareImage
								src={trainer?.avatar_image_url ?? ''}
								variant="rounded"
								size={160}
							/>
						</center>
					</Box>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Grid container>
						{vibes.map((vibe) => (
							<Grid
								item
								key={vibe.id}
								xs={12}
								sm={6}
								lg={4}
							>
								<Box p={4}>
									<center>
										<Typography variant="body2">
											{vibe.name} Image
										</Typography>
										<SquareImage
											src={imgUrlByVibeId[vibe.id] ?? ''}
											variant="rounded"
											size={160}
										/>
									</center>
								</Box>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Box>
					<ReactAudioPlayer
						src={trainer.media_url || ''}
						controls
					/>
				</Box>
			</Grid>
			<Grid item xs={12} sm={8}>
				<List>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Name
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{trainer.name ? trainer.name : '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">Bio</Typography>
							}
							secondary={
								<Typography variant="body1">
									{trainer.bio ? trainer.bio : '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								<Typography variant="body2">
									Category
								</Typography>
							}
							secondary={
								<Typography variant="body1">
									{trainer.category
										? trainer.category
										: '-'}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem>
						<Box flex="column">
							<ListItemText
								primary={
									<Typography variant="body2">
										Featured
									</Typography>
								}
							/>
							<SwitchInput
								component="span"
								value={trainer.featured === true ? true : false}
								label=""
								name="featured"
								handleChange={toggleFeatured}
							/>
						</Box>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	)
}

export default TrainerDetails

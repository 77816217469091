import React from 'react'
import {
	AppBar,
	Toolbar,
	Box,
	IconButton,
	Grid,
	Container,
	Typography,
	withWidth,
} from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ChevronRight } from '@material-ui/icons'

const Subheader = ({ title, buttons, width, ...props }) => {
	const isMobile = ['xs', 'sm'].includes(width)

	const path = useLocation()

	return (
		<StyledBox
			width="100%"
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			mb={4}
			p={1}
		>
			<Box
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
			>
				<Box ml={isMobile ? 6 : 3} mt={1}>
					<Typography variant="h4">{title}</Typography>
				</Box>
			</Box>
			<Box
				display="flex"
				display="flex"
				justifyContent="flex-end"
			>
				{buttons &&
					buttons.map((button, i) => (
						<Box key={i} m={1}>
							{button}
						</Box>
					))}
			</Box>
		</StyledBox>
	)
}
export default withWidth()(Subheader)

const StyledBox = styled(Box)`
	background-color: white;
	border-bottom: 1px solid #eee;
`

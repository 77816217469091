import React, { useEffect } from 'react'
import { Button, Container } from '@material-ui/core'
import { useAlerts, useAudios, useSelected } from 'hooks'
import { Subheader } from 'components'
import AudioTable from 'containers/audios/AudioTable'
import AudioToolbar from 'containers/audios/AudioToolbar'

const CarouselAddAudio = ({
	title,
	audioQuery,
	addToCarousel,
	goBack,
	...props
}) => {
	const audioState = useAudios({})
	const { findAudios, reloadAudios } = audioState

	useEffect(() => {
		const run = async () => {
			await findAudios(audioQuery)
		}
		run()
	}, [])

	const { showAlertSuccess } = useAlerts()

	const selectedItemState = useSelected()
	const {
		selected,
		selectedIds,
		handleClear,
		totalSelected,
	} = selectedItemState

	const handleAddToCarousel = async () => {
		await addToCarousel(selectedIds)
		handleClear()
		showAlertSuccess('Added to carousel')
		reloadAudios()
	}

	return (
		<>
			<Subheader
				title={title}
				buttons={[
					<Button
						variant="contained"
						onClick={() => goBack()}
						color="primary"
					>
						Done
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				<AudioTable
					audioQuery={audioQuery}
					audioState={audioState}
					selectedItemState={selectedItemState}
					renderToolbar={() => (
						<AudioToolbar
							selected={selected}
							selectedIds={selectedIds}
							handleClear={handleClear}
							totalSelected={totalSelected}
							confirmText="Add to Carousel"
							confirmAction={handleAddToCarousel}
							cancelAction={handleClear}
						/>
					)}
				/>
			</Container>
		</>
	)
}

export default CarouselAddAudio

import React, { useContext, useState } from 'react'
import { useAlerts, useAuth } from 'hooks'
import { AppContext } from 'context'
import {
	Link as RouterLink,
	useNavigate,
} from 'react-router-dom'
import {
	Box,
	Button,
	Container,
	Grid,
	Link,
	Typography,
} from '@material-ui/core'
import { Column, TextInput } from 'components'

const ResetPassword = ({ ...props }) => {
	const [email, setEmail] = useState()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const { authenticated, currentUser } =
		useContext(AppContext)

	const navigate = useNavigate()

	const { forgotPassword } = useAuth()

	const handleSubmit = async () => {
		try {
			await forgotPassword(email)
			navigate('/verify_pin')
			showAlertSuccess(
				'We have sent you password reset instructions'
			)
		} catch (e) {
			console.log(e)
			showAlertError("A user with that email doesn't exist")
		}
	}

	const handleChange = ({ target }) =>
		setEmail(target.value)

	return (
		<Container maxWidth="sm">
			<Box my={10}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextInput
							fullWidth
							required={true}
							name="email"
							label="Email"
							placeholder="Enter your email"
							handleChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							size="large"
							variant="contained"
							color="primary"
							onClick={handleSubmit}
						>
							Send Instructions
						</Button>
					</Grid>
				</Grid>
				<Box my={4} />
				<Typography variant="body2">
					<Link component={RouterLink} to="/login">
						Sign in
					</Link>
				</Typography>
			</Box>
		</Container>
	)
}

export default ResetPassword

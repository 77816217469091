import React from 'react'
import AspectRatioImage from './AspectRatioImage'

const DEFAULT_WIDTH = 258
const DEFAULT_HEIGHT = 338

const InstagramImage = AspectRatioImage({
	defaultWidth: DEFAULT_WIDTH,
	defaultHeight: DEFAULT_HEIGHT,
})

export default InstagramImage

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMuneTopPicks } from 'hooks'
import CarouselAddAudio from 'containers/audios/CarouselAddAudio'

const MuneTopPicksAddAudio = ({ ...props }) => {
	const navigate = useNavigate()

	const audioQuery = {
		'audios.class_type': 'on_demand',
		'audios.is_mune_top_pick': false,
	}

	const { addAllMuneTopPicks } = useMuneTopPicks()

	return (
		<CarouselAddAudio
			title="MUNE Top Picks Carousel > Add Audios"
			audioQuery={audioQuery}
			addToCarousel={(selectedIds) =>
				addAllMuneTopPicks(selectedIds)
			}
			goBack={() =>
				navigate('/curated_lists/mune_top_picks')
			}
		/>
	)
}

export default MuneTopPicksAddAudio
